.is--ouchi {
    .rwd002-container {
        margin-top: 0;
    }
    .page-link {
        margin-top: 90px;
        @include media-down(md) {
            margin-top: 30px;
        }
    }
}
._list-anchor {
    margin: 0 auto;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 9px;
    width: 100%;
    @include media-up(md) {
        max-width: 640px;
    }
    @include media-down(md) {
        flex-direction: column;
        gap: 6px;
        width: 285px;
        margin: auto;
    }
    &__item {
        transition: $transition-base;
        width: 205px;
        height: 35px;
        font-size: calc(12 / 16 * $font-size-base);
        letter-spacing: 0.08em;
        border: solid 1px #eceeee;
        border-radius: 50px;
        @include media-down(md) {
            width: 100%;
        }
        @media (hover: hover) {
            &:hover {
                background-color: #eceeee;
            }
        }
        a {
            transition: $transition-base;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            text-decoration: none !important;
            line-height: 1;
            background: svgUrlFunc(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.08 5.15"><defs><style>.cls-1{fill:none;stroke:#2a2c2c;stroke-linecap:round;stroke-linejoin:round;}</style></defs><path class="cls-1" d="M7.58.5l-3.54,4.15L.5.5"/></svg>'
                )
                no-repeat right 14px center / 7px auto;
        }
    }
}
.ouchi-headline {
    position: relative;
    ._illust {
        position: absolute;
        top: -100px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        margin: auto;
        width: 100%;
        max-width: 1090px;
        margin: auto;
        padding-right: map-get($spacers, 3);
        @include media-down(md) {
            display: none;
        }
    }
    ._main-img {
        width: 100%;
        height: auto;
    }
}
.ouchi-lead {
    &__container {
        padding: 80px map-get($spacers, 3) 60px;
        @include media-down(md) {
            padding-top: 40px;
        }
    }
    &__title {
        margin-bottom: 35px;
        font-size: calc(30 / 16 * $font-size-base);
        font-weight: bold;
        text-align: center;
        color: #454547;
        letter-spacing: 0.16em;
        line-height: calc(72 / 30);
        @include media-down(md) {
            margin-bottom: 30px;
            font-size: calc(20 / 16 * $font-size-base);
            letter-spacing: 0.1em;
            line-height: calc(48 / 20);
        }
        @include media-down(sm) {
            position: relative;
            text-align: left;

            span {
                display: inline;
                padding: 15px 0;
                background-image: linear-gradient(#373737, #373737);
                background-position: 0 100%;
                background-repeat: no-repeat;
                background-size: 100% 3px;
            }
            &::before {
                // content: "";
                // position: absolute;
                // left: 0;
                // bottom: -1px;
                // width: 100%;
                // height: 3px;
                // background-color: #373737;
            }
        }
        @media (max-width: 480px) {
            // font-size: calc(18 / 16 * $font-size-base);
        }
        ._pc-line {
            display: block;
            // width: 657px;
            width: 670px;
            padding: 20px 0;
            line-height: 1;
            &::before {
                left: 11px;
            }
            @include media-down(md) {
                // width: 414px;
                width: 425px;
                padding: 15px 0;
                background-size: 100% 2px;
                &::before {
                    left: 8px;
                }
            }
            @include media-up(sm) {
                position: relative;
                margin: auto;
                text-align: left;
                // background-image: linear-gradient(#373737, #373737);
                // background-position: 0 100%;
                // background-repeat: no-repeat;
                // background-size: 100% 3px;
                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    width: 92%;
                    height: 3px;
                    background-color: #373737;
                }
            }
            @include media-down(sm) {
                padding: 0;
                text-align: center;
            }
        }
        ._sp-line {
            @include media-down(sm) {
                display: block;
                padding: 4px 0 0;
                background-image: linear-gradient(#373737, #373737);
                background-position: 0 100%;
                background-repeat: no-repeat;
                background-size: 100% 3px;
                span {
                    transform: translateX(-8px);
                    display: inline-block;
                }
            }
        }
    }
    &__text {
        margin-bottom: 65px;
        font-size: calc(17 / 16 * $font-size-base);
        font-weight: bold;
        text-align: center;
        color: #454547;
        line-height: calc(38 / 17);
        letter-spacing: 0.1em;
        @include media-down(md) {
            margin-bottom: 40px;
            line-height: calc(29 / 17);
        }
        @include media-down(sm) {
            text-align: left;
        }
    }
}
.ouchi-concept {
    position: relative;
    z-index: 1;
    margin-bottom: -1px;
    padding-inline: map-get($spacers, 3);
    padding-bottom: 135px;
    background-color: #efefef;
    @include media-down(md) {
        padding-bottom: 80px;
    }
    &__inner {
        width: 100%;
        max-width: 1100px;
        margin-inline: auto;
    }
    &__title {
        @include c-title-local(center);
        margin-bottom: 55px;
        @include media-down(md) {
            margin-bottom: 40px;
        }
    }
    figure {
        position: relative;
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
        margin-bottom: 60px;
        @include media-down(md) {
            margin-bottom: 35px;
        }
        ._illust {
            position: absolute;
            top: -88px;
            left: -30px;
            @include media-down(md) {
                top: -190px;
                left: 0;
                width: 110px;
                height: auto;
            }
        }
        ._img-main {
            width: 100%;
            height: auto;
        }
    }
    &__subTitle {
        margin-bottom: 30px;
        font-size: calc(39 / 16 * $font-size-base);
        font-weight: bold;
        text-align: center;
        color: #454547;
        letter-spacing: 0.16em;
        line-height: 1;
        @include media-down(md) {
            margin-bottom: 20px;
            font-size: calc(27 / 16 * $font-size-base);
        }
    }
    &__text {
        margin-bottom: 35px;
        font-size: calc(15 / 16 * $font-size-base);
        text-align: center;
        color: #454547;
        letter-spacing: 0.12em;
        line-height: calc(32 / 15);
        @include media-down(md) {
            margin-bottom: 25px;
        }
    }
    &__list {
        // display: grid;
        // grid-template-columns: repeat(2, 1fr);
        // gap: 15px 50px;
        width: 100%;
        max-width: 692px;
        margin: 0 auto 75px;
        padding: 0;
        list-style-type: none;
        @include media-up(md) {
            column-count: 2;
            gap: 50px;
        }
    }
    &__item {
        padding: 15px 3px 15px 37px;
        font-size: calc(17 / 16 * $font-size-base);
        font-weight: bold;
        font-feature-settings: 'palt';
        letter-spacing: 0.12em;
        color: #454547;
        border-bottom: solid 1px #454547;
        background: url(/assets/img/icon/icon-check.svg) no-repeat left 3px
            center / 20px auto;
        @include media-down(md) {
            font-size: calc(16 / 16 * $font-size-base);
        }
        &:nth-of-type(odd) {
            @include media-up(md) {
                margin-bottom: 15px;
            }
        }
    }
    &__figure {
        display: grid;
        @include media-up(md) {
            grid-template-columns: repeat(2, 1fr);
            gap: 40px;
        }
        @include media-down(md) {
            grid-template-columns: 1fr;
            gap: 35px;
        }
        ._point {
            @include media-down(md) {
                width: 100%;
                max-width: 450px;
                margin: auto;
            }
        }
        img {
            width: 100%;
            height: auto;
        }
    }
}
.ouchi-story {
    overflow: hidden;
    padding: 0 30px 120px;
    background: url(/assets/img/webp/ouchi/back-story@2x.webp) no-repeat top
        center / cover;
    @include media-down(md) {
        padding: 0 map-get($spacers, 3) 50px;
    }
    @include media-down(sm) {
        background-image: url(/assets/img/webp/ouchi/back-story_sp@2x.webp);
    }
    &__title {
        @include c-title-local(center);
        margin-bottom: 70px;
        @include media-down(md) {
            margin-bottom: 30px;
        }
        &::before {
            background-color: #fff;
        }
        ._ja {
            color: #fff;
        }
        ._en {
            color: #fff;
        }
    }
    &__list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    &__item {
        position: relative;
        width: 100%;
        max-width: 1340px;
        margin-inline: auto;
        @include media-up(lg) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &:not(:last-of-type) {
            margin-bottom: 35px;
            @include media-down(md) {
                margin-bottom: 30px;
            }
        }
        &:nth-of-type(odd) {
            ._content {
                right: 77px;
                @include media-down(xl) {
                    right: 45px;
                }
            }
        }
        &:nth-of-type(even) {
            figure {
                margin-left: auto;
            }
            ._content {
                left: 77px;
                @include media-down(xl) {
                    left: 45px;
                }
            }
        }
        figure {
            margin: 0;
            @include media-up(lg) {
                width: calc(50% + 3px);
                max-width: 50.2%;
                height: 429px;
            }
            @include media-down(lg) {
                width: 100%;
            }
            img {
                width: 100%;
                height: auto;
                @include media-up(lg) {
                    object-fit: cover;
                    object-position: center;
                    height: 100%;
                }
            }
        }
        ._content {
            width: 651px;
            max-width: 48.5%;
            height: calc(100% - 60px);
            padding-left: 9%;
            padding-right: map-get($spacers, 3);
            background-color: #fff;
            @include media-down(xl) {
                padding-left: 70px;
            }
            @media (max-width: 1100px) {
                padding-inline: map-get($spacers, 3);
            }
            @include media-up(lg) {
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            @include media-down(lg) {
                width: 100%;
                max-width: inherit;
                height: auto;
                padding-block: 30px;
            }
            ._pattern {
                @include font-en();
                margin-bottom: 20px;
                font-size: calc(12 / 16 * $font-size-base);
                font-feature-settings: 'palt';
                font-weight: 600;
                color: #838081;
                letter-spacing: 0.16em;
            }
            ._title {
                margin-bottom: 20px;
                font-size: calc(26 / 16 * $font-size-base);
                font-feature-settings: 'palt';
                font-weight: bold;
                color: #838081;
                letter-spacing: 0.16em;
                @include media-down(md) {
                    font-size: calc(22 / 16 * $font-size-base);
                }
            }
            ._text {
                margin: 0;
                font-size: calc(15 / 16 * $font-size-base);
                font-feature-settings: 'palt';
                color: #454547;
                letter-spacing: 0.08em;
                line-height: calc(33 / 15);
                @media (max-width: 1100px) {
                    ._none {
                        display: none;
                    }
                }
                @include media-down(md) {
                    line-height: calc(28 / 15);
                }
            }
        }
    }
}
.ouchi-case {
    padding-inline: map-get($spacers, 3);
    padding-bottom: 120px;
    @include media-down(md) {
        padding-bottom: 75px;
    }
    &__inner {
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
    }
    &__title {
        @include c-title-local(center);
        margin-bottom: 55px;
        @include media-down(md) {
            margin-bottom: 45px;
        }
        @include media-down(sm) {
            ._ja {
                text-align: center;
            }
        }
    }
    ._head {
        margin-bottom: 60px;
        @include media-down(md) {
            margin-bottom: 35px;
        }
        ._title {
            margin-bottom: 25px;
            font-size: calc(30 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            font-weight: bold;
            color: #373737;
            letter-spacing: 0.12em;
            text-align: center;
            @include media-down(md) {
                margin-bottom: 23px;
                font-size: calc(29 / 16 * $font-size-base);
            }
        }
        ._locate {
            margin-bottom: 25px;
            font-size: calc(13 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            color: #373737;
            letter-spacing: 0.08em;
            text-align: center;
        }
        ._caption {
            margin: 0;
            font-size: calc(17 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            font-weight: bold;
            color: #373737;
            letter-spacing: 0.14em;
            text-align: center;
            @include media-down(md) {
                font-size: calc(16 / 16 * $font-size-base);
            }
        }
    }
    figure {
        display: block;
        margin: 0;
        img {
            width: 100%;
            height: auto;
        }
    }
    ._post {
        @include media-up(lg) {
            padding: 80px 80px 60px;
        }
        @include media-down(lg) {
            padding: 80px 30px 60px;
        }
        @include media-down(md) {
            padding: 30px 0 50px;
        }
        ._title {
            position: relative;
            margin-bottom: 33px;
            padding-bottom: 20px;
            font-size: calc(21 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            font-weight: bold;
            color: #7a7072;
            letter-spacing: 0.08em;
            line-height: 1.6;
            @include media-down(md) {
                margin-bottom: 22px;
                font-size: calc(18 / 16 * $font-size-base);
            }
            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 50px;
                height: 1px;
                background-color: #7a7072;
            }
        }
        ._text {
            margin-bottom: 45px;
            font-size: calc(15 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            letter-spacing: 0.08em;
            line-height: calc(31 / 15);
            @include media-down(md) {
                margin-bottom: 40px;
                letter-spacing: 0.06em;
                line-height: calc(28 / 15);
            }
        }
    }
    ._owner-detail {
        display: flex;
        align-items: center;
        gap: 45px;
        @include media-down(md) {
            flex-direction: column;
            gap: 35px;
        }
        img {
            flex-shrink: 0;
            max-width: 100%;
            height: auto;
        }
        &__content {
            width: 100%;
            p {
                margin-bottom: 5px;
                font-family: 'Roboto', $font-family-sans-serif;
                font-size: calc(16 / 16 * $font-size-base);
                font-feature-settings: 'palt';
                // color: #838081;
                color: #7a7072;
                letter-spacing: 0.08em;
                line-height: calc(31 / 16);
            }
            table {
                @include table-typeB();
            }
        }
    }
    &__bnr {
        transition: $transition-base;
        display: block;
        img {
            width: 100%;
            height: auto;
        }
        @media (hover: hover) {
            &:hover {
                opacity: 0.8;
            }
        }
    }
    &__btn {
        @include c-button-open();
        margin-top: 30px;
        @include media-down(md) {
            margin-top: 10px;
        }
    }
}
.ouchi-support {
    padding-inline: map-get($spacers, 3);
    padding-bottom: 95px;
    background-color: #efefef;
    @include media-down(md) {
        padding-bottom: 60px;
    }
    &__inner {
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
    }
    &__title {
        @include c-title-local(center);
        margin-bottom: 40px;
        @include media-down(md) {
            margin-bottom: 25px;
        }
    }
    &__lead {
        margin-bottom: 45px;
        font-size: calc(17 / 16 * $font-size-base);
        font-feature-settings: 'palt';
        font-weight: bold;
        color: #373737;
        letter-spacing: 0.14em;
        line-height: calc(30 / 17);
        text-align: center;
        @include media-down(md) {
            margin-bottom: 25px;
            letter-spacing: 0.1em;
        }
    }
    figure {
        display: flex;
        justify-content: center;
        margin-bottom: 70px;
        transform: translateX(22px);
        @include media-down(md) {
            transform: translateX(20px);
            margin-bottom: 25px;
        }
        @include media-down(sm) {
            transform: none;
        }
        img {
            max-width: 100%;
            height: auto;
            @include media-down(md) {
                max-width: calc(100% + 15px);
            }
        }
    }
    &__example {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 25px;
        background-color: #fff;
        border: solid 1px #454547;
        @include media-up(md) {
            height: 190px;
        }
        ._title {
            margin-bottom: 20px;
            font-size: calc(18 / 16 * $font-size-base);
            color: #454547;
            letter-spacing: 0.16em;
            @include media-down(md) {
                font-size: calc(17 / 16 * $font-size-base);
            }
        }
        ._text {
            margin: 0;
            font-size: calc(15 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            text-align: center;
            color: #373737;
            letter-spacing: 0.08em;
            line-height: calc(28 / 15);
            @include media-down(md) {
                text-align: left;
            }
        }
    }
}
.ouchi-flow {
    padding-inline: map-get($spacers, 3);
    &__inner {
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
    }
    &__title {
        @include c-title-local(center);
        margin-bottom: 20px;
        @include media-down(md) {
            margin-bottom: 10px;
        }
    }
    &__list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    &__item {
        display: flex;
        background: url(/assets/img/webp/ouchi/fig-border@2x.webp) no-repeat
            bottom center / 100% auto;
        @include media-up(lg) {
            align-items: center;
            min-height: 145px;
            padding-block: 15px 35px;
        }
        @include media-down(lg) {
            flex-direction: column;
            gap: 10px;
            padding-block: 20px 30px;
            background-size: auto 8px;
        }
        @include media-down(sm) {
            background-image: url(/assets/img/webp/ouchi/fig-border_sp@2x.webp);
            background-size: 100% auto;
        }
        &:first-of-type {
            ._body {
                padding-right: 0;
                p {
                    @include media-up(lg) {
                        margin-top: 30px;
                    }
                }
            }
        }
        &:last-of-type {
            background-image: none !important;
        }
        ._head {
            display: flex;
            align-items: center;
            @include media-up(lg) {
                flex-shrink: 0;
                width: 300px;
                height: 65px;
                border-right: solid 1px #d6d6d6;
            }
            ._step {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 3px;
                width: 58px;
                height: 58px;
                margin: 0 18px 0 33px;
                @include font-en();
                font-size: calc(10 / 16 * $font-size-base);
                font-feature-settings: 'palt';
                font-weight: 600;
                color: #fff;
                letter-spacing: 0.1em;
                line-height: 1;
                background-color: #ccc45c;
                border-radius: 50%;
                @include media-down(lg) {
                    margin-left: 0;
                }
                ._num {
                    font-size: calc(13 / 16 * $font-size-base);
                }
            }
            ._title {
                margin: 0;
                font-size: calc(18 / 16 * $font-size-base);
                font-feature-settings: 'palt';
                color: #454547;
                letter-spacing: 0.08em;
                line-height: calc(24 / 18);
            }
        }
        ._body {
            @include media-up(lg) {
                padding-inline: 42px 62px;
            }
            p {
                margin: 0;
                font-size: calc(14 / 16 * $font-size-base);
                font-feature-settings: 'palt';
                color: #454547;
                letter-spacing: 0.08em;
                line-height: calc(28 / 14);
            }
            img {
                display: block;
                margin: 25px 0 35px;
            }
        }
        ._btnwrap {
            display: flex;
            align-items: center;
            gap: 23px;
            margin-top: 12px;
            @include media-down(sm) {
                flex-direction: column;
                gap: 18px;
            }
            ._btn {
                transition: $transition-base;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 271px;
                height: 47px;
                font-size: calc(13 / 16 * $font-size-base);
                font-feature-settings: 'palt';
                text-decoration: none !important;
                color: #373737;
                letter-spacing: 0.08em;
                border: solid 1px #454547;
                border-radius: 2px;
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.6 7.47"><defs><style>.cls-1{fill:none;stroke:#454547;stroke-miterlimit:10;}</style></defs><line class="cls-1" x1="0" y1="3.74" x2="12.6" y2="3.74"/><line class="cls-1" x1="11.02" y1="3.74" x2="7.65" y2=".35"/><line class="cls-1" x1="7.65" y1="7.12" x2="11.02" y2="3.74"/></svg>'
                    )
                    no-repeat right 11px center / 13px auto;
                @media (hover: hover) {
                    &:hover {
                        background-position: right 6px center;
                    }
                }
            }
            ._tel {
                flex-shrink: 0;
                display: inline-block;
                padding-left: 27px;
                font-size: calc(24 / 16 * $font-size-base);
                font-family: 'Roboto', $font-family-sans-serif;
                font-feature-settings: 'palt';
                letter-spacing: 0.12em;
                line-height: 1;
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.97 25.97"><defs><style>.cls-1{fill:#ccc45c;}.cls-1,.cls-2{stroke-width:0px;}.cls-2{fill:#fff;}</style></defs><circle class="cls-1" cx="12.99" cy="12.99" r="12.99"/><path class="cls-2" d="M10.18,7.32l1.25,1.25c.18.18.17.49-.03.69l-.51.5c-.18.18-.21.46-.07.64,0,0,.17,1.16,1.54,2.53,1.39,1.39,2.49,1.5,2.49,1.5.19.14.46.11.64-.07l.5-.5c.2-.2.51-.21.69-.03l1.25,1.25c.18.18.17.49-.03.69,0,0-.07.07-.82.82-.87.87-4.06-.8-5.85-2.58-1.78-1.78-3.45-4.97-2.58-5.85.75-.75.82-.82.82-.82.2-.2.51-.21.69-.03Z"/></svg>'
                    )
                    no-repeat left center / 18px auto;
                @include media-down(md) {
                }
            }
        }
    }
}
