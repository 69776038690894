.is--renovetion {
    .rwd002-container {
        margin-top: 0;
    }
    .page-link {
        margin-top: 115px;
        @include media-down(md) {
            margin-top: 50px;
        }
    }
}
.renovetion-headline {
    position: relative;
    ._illust {
        position: absolute;
        top: -167px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        margin: auto;
        width: 100%;
        max-width: 940px;
        margin: auto;
        padding-right: map-get($spacers, 3);
        @include media-down(md) {
            display: none;
        }
    }
    ._main-img {
        width: 100%;
        height: auto;
    }
}
.renovetion-lead {
    &__container {
        padding: 80px map-get($spacers, 3) 60px;
        @include media-down(md) {
            padding-top: 40px;
        }
    }
    &__title {
        margin-bottom: 35px;
        font-size: calc(30 / 16 * $font-size-base);
        font-weight: bold;
        text-align: center;
        color: #454547;
        letter-spacing: 0.16em;
        line-height: calc(72 / 30);
        @include media-down(md) {
            margin-bottom: 30px;
            font-size: calc(20 / 16 * $font-size-base);
            letter-spacing: 0.1em;
            line-height: calc(48 / 20);
        }
        @include media-down(sm) {
            text-align: left;
        }
        @media (max-width: 480px) {
          font-size: calc(18 / 16 * $font-size-base);
        }
        span {
            background-image: linear-gradient(#373737, #373737);
            background-position: 0 100%;
            background-repeat: no-repeat;
            background-size: 100% 3px;
            padding: 23px 65px;
            @include media-down(md) {
                padding: 15px 65px;
                background-size: 100% 2px;
            }
            @include media-down(sm) {
                display: block;
                // display: block;
                // padding: 0;
                padding: 4px 0 0;
                text-align: center;
            }
        }
        ._pc-line {
            padding: 23px 65px;
            @include media-down(md) {
                padding: 15px 65px;
                background-size: 100% 2px;
            }
            @include media-up(sm) {
                background-image: linear-gradient(#373737, #373737);
                background-position: 0 100%;
                background-repeat: no-repeat;
                background-size: 100% 3px;
            }
            @include media-down(sm) {
                display: block;
                padding: 0;
                text-align: center;
            }
        }
        ._sp-line {
            @include media-down(sm) {
                display: block;
                padding: 4px 0 0;
                background-image: linear-gradient(#373737, #373737);
                background-position: 0 100%;
                background-repeat: no-repeat;
                background-size: 100% 3px;
            }
        }
    }
    &__text {
        margin-bottom: 65px;
        font-size: calc(17 / 16 * $font-size-base);
        font-weight: bold;
        text-align: center;
        color: #454547;
        line-height: calc(38 / 17);
        letter-spacing: 0.1em;
        @include media-down(md) {
            margin-bottom: 40px;
            line-height: calc(29 / 17);
        }
        @include media-down(sm) {
            text-align: left;
        }
    }
}
.renovetion-story {
    overflow: hidden;
    padding: 0 30px 120px;
    background: url(/assets/img/webp/renovetion/back-story@2x.webp) no-repeat
        top center / cover;
    @include media-down(md) {
        padding: 0 map-get($spacers, 3) 50px;
    }
    @include media-down(sm) {
        background-image: url(/assets/img/webp/renovetion/back-story_sp@2x.webp);
    }
    &__title {
        @include c-title-local(center);
        margin-bottom: 70px;
        @include media-down(md) {
            margin-bottom: 30px;
        }
        &::before {
            background-color: #fff;
        }
        ._ja {
            color: #fff;
        }
        ._en {
            color: #fff;
        }
    }
    ._sec-border {
        width: 100%;
        max-width: 1340px;
        margin: 105px auto 75px;
        border-bottom: dashed 1px #fff;
        @include media-down(lg) {
            margin-block: 40px;
        }
    }
    &__list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    &__item {
        position: relative;
        width: 100%;
        max-width: 1340px;
        margin-inline: auto;
        @include media-up(lg) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &:not(:last-of-type) {
            margin-bottom: 35px;
            @include media-down(md) {
                margin-bottom: 30px;
            }
        }
        &:nth-of-type(odd) {
            ._content {
                right: 77px;
                @include media-down(xl) {
                    right: 45px;
                }
            }
        }
        &:nth-of-type(even) {
            figure {
                margin-left: auto;
            }
            ._content {
                left: 77px;
                @include media-down(xl) {
                    left: 45px;
                }
            }
        }
        figure {
            margin: 0;
            @include media-up(lg) {
                width: calc(50% + 3px);
                max-width: 50.2%;
                height: 429px;
            }
            @include media-down(lg) {
                width: 100%;
            }
            img {
                width: 100%;
                height: auto;
                @include media-up(lg) {
                    object-fit: cover;
                    object-position: center;
                    height: 100%;
                }
            }
        }
        ._content {
            width: 651px;
            max-width: 48.5%;
            height: calc(100% - 60px);
            padding-left: 9%;
            padding-right: 7%;
            // padding-right: map-get($spacers, 3);
            background-color: #fff;
            @include media-down(xl) {
                padding-left: 70px;
            }
            @media (max-width: 1100px) {
                padding-inline: map-get($spacers, 3);
            }
            @include media-up(lg) {
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            @include media-down(lg) {
                width: 100%;
                max-width: inherit;
                height: auto;
                padding-block: 30px;
            }
            ._pattern {
                @include font-en();
                margin-bottom: 20px;
                font-size: calc(12 / 16 * $font-size-base);
                font-feature-settings: 'palt';
                font-weight: 600;
                color: #838081;
                letter-spacing: 0.16em;
            }
            ._title {
                margin-bottom: 20px;
                font-size: calc(26 / 16 * $font-size-base);
                font-feature-settings: 'palt';
                font-weight: bold;
                color: #838081;
                letter-spacing: 0.16em;
                line-height: calc(37 / 26);
                @include media-down(md) {
                    font-size: calc(22 / 16 * $font-size-base);
                    line-height: calc(31 / 22);
                }
            }
            ._text {
                margin: 0;
                font-size: calc(15 / 16 * $font-size-base);
                font-feature-settings: 'palt';
                color: #454547;
                letter-spacing: 0.08em;
                line-height: calc(33 / 15);
                @media (max-width: 1100px) {
                    ._none {
                        display: none;
                    }
                }
                @include media-down(md) {
                    line-height: calc(28 / 15);
                    br {
                        display: none;
                    }
                }
            }
        }
    }
}
.renovetion-works {
    position: relative;
    padding: 0 map-get($spacers, 3) 100px;
    background-color: #efefef;
    @include media-down(md) {
        padding-bottom: 40px;
    }
    @include media-down(sm) {
        padding-inline: 0;
    }
    &__inner {
        position: relative;
        z-index: 1;
        width: 100%;
        max-width: 1200px;
        margin-inline: auto;
        @include media-down(sm) {
            overflow: hidden;
        }
    }
    &__title {
        @include c-title-local(center);
        margin-bottom: 85px;
        @include media-down(md) {
            margin-bottom: 50px;
        }
    }
    .swiper-area {
        position: relative;
        width: 100%;
        padding-bottom: 70px;
        @include media-down(md) {
            padding-bottom: 40px;
        }
    }
    .works_slide {
        @include media-down(sm) {
            overflow: visible;
            width: 300px;
        }
    }
    .btn-wrap {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 25px;
        width: 100%;
        .works-prev,
        .works-next {
            position: static;
            width: 35px;
            height: 6px;
            &::after {
                display: none;
            }
        }
        .works-prev {
            background: svgUrlFunc(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.32 6.36"><defs><style>.cls-1{fill:none;stroke:#454547;stroke-miterlimit:10;}</style></defs><line class="cls-1" x1="34.32" y1="5.86" x2="0" y2="5.86"/><line class="cls-1" x1="2.57" y1="5.86" x2="8.05" y2=".35"/></svg>'
                )
                no-repeat center / contain;
        }
        .works-next {
            background: svgUrlFunc(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.32 6.36"><defs><style>.cls-1{fill:none;stroke:#454547;stroke-miterlimit:10;}</style></defs><line class="cls-1" x1="0" y1="5.86" x2="34.32" y2="5.86"/><line class="cls-1" x1="31.75" y1="5.86" x2="26.26" y2=".35"/></svg>'
                )
                no-repeat center / contain;
        }
    }
    &__btnwrap {
        @include media-up(md) {
            position: absolute;
            top: 175px;
            right: 50px;
        }
        @include media-down(md) {
            display: flex;
            justify-content: flex-end;
            margin-top: 27px;
        }
        @include media-down(sm) {
            padding-inline: map-get($spacers, 3);
        }
    }
    &__btn {
        @include c-button-view();
    }
}
.renovetion-works-item {
    a {
        position: relative;
        text-decoration: none !important;
    }
    &__img-wrap {
        overflow: hidden;
        aspect-ratio: 372 / 263;
        width: 100%;
        margin-bottom: 22px;
        border-radius: 5px;
        img {
            transition: $transition-base;
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        }
    }
    .cate {
        position: absolute;
        z-index: 1;
        top: 13px;
        left: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 86px;
        height: 24px;
        padding: 0 5px;
        font-size: calc(10 / 16 * $font-size-base);
        font-weight: bold;
        letter-spacing: 0.08em;
        background-color: #fff;
        border-radius: 3px;
    }
    &__body {
        padding-inline: 12px;
    }
    .title {
        margin-bottom: 17px;
        font-size: calc(18 / 16 * $font-size-base);
        font-feature-settings: 'palt';
        font-weight: bold;
        color: #454547;
        letter-spacing: 0.12em;
    }
    .tags {
        display: flex;
        gap: 10px;
        .tag {
            font-size: calc(10 / 16 * $font-size-base);
            color: #7a7072;
            letter-spacing: 0.08em;
        }
    }
    @media (hover: hover) {
        &:hover {
            img {
                transform: scale(1.1);
            }
            .title {
                text-decoration: underline !important;
            }
        }
    }
}
.renovetion-flow {
    padding-inline: map-get($spacers, 3);
    padding-bottom: 85px;
    @include media-down(md) {
        padding-bottom: 45px;
    }
    &__inner {
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
    }
    &__title {
        @include c-title-local(center);
        margin-bottom: 20px;
        @include media-down(md) {
            margin-bottom: 10px;
        }
    }
    &__list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    &__item {
        display: flex;
        background: url(/assets/img/webp/ouchi/fig-border@2x.webp) no-repeat
            bottom center / 100% auto;
        @include media-up(lg) {
            align-items: center;
            min-height: 145px;
            padding-block: 15px 35px;
        }
        @include media-down(lg) {
            flex-direction: column;
            gap: 10px;
            padding-block: 20px 30px;
            background-size: auto 8px;
        }
        @include media-down(sm) {
            background-image: url(/assets/img/webp/ouchi/fig-border_sp@2x.webp);
            background-size: 100% auto;
        }
        &:first-of-type {
            ._body {
                padding-right: 0;
                p {
                    @include media-up(lg) {
                        margin-top: 30px;
                    }
                }
            }
        }
        &:last-of-type {
            background-image: none !important;
        }
        ._head {
            display: flex;
            align-items: center;
            @include media-up(lg) {
                flex-shrink: 0;
                width: 300px;
                height: 65px;
                border-right: solid 1px #d6d6d6;
            }
            &._top {
                @include media-up(lg) {
                    margin: 15px 0 auto 0;
                }
            }
            ._step {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 3px;
                width: 58px;
                height: 58px;
                margin: 0 18px 0 33px;
                @include font-en();
                font-size: calc(10 / 16 * $font-size-base);
                font-feature-settings: 'palt';
                font-weight: 600;
                color: #fff;
                letter-spacing: 0.1em;
                line-height: 1;
                background-color: #ccc45c;
                border-radius: 50%;
                @include media-down(lg) {
                    margin-left: 0;
                }
                ._num {
                    font-size: calc(13 / 16 * $font-size-base);
                }
            }
            ._title {
                margin: 0;
                font-size: calc(18 / 16 * $font-size-base);
                font-feature-settings: 'palt';
                color: #454547;
                letter-spacing: 0.08em;
                line-height: calc(24 / 18);
            }
        }
        ._body {
            @include media-up(lg) {
                padding: 20px 62px 20px 42px;
            }
            p {
                margin: 0;
                font-size: calc(14 / 16 * $font-size-base);
                font-feature-settings: 'palt';
                color: #454547;
                letter-spacing: 0.08em;
                line-height: calc(28 / 14);
            }
            img {
                display: block;
                margin: 25px 0 0;
                max-width: 100%;
                height: auto;
                @include media-down(md) {
                    margin-top: 15px;
                }
            }
        }
        ._btnwrap {
            display: flex;
            align-items: center;
            gap: 23px;
            margin-top: 12px;
            @include media-down(sm) {
                flex-direction: column;
                gap: 18px;
            }
            ._btn {
                transition: $transition-base;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 271px;
                height: 47px;
                font-size: calc(13 / 16 * $font-size-base);
                font-feature-settings: 'palt';
                text-decoration: none !important;
                color: #373737;
                letter-spacing: 0.08em;
                border: solid 1px #454547;
                border-radius: 2px;
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.6 7.47"><defs><style>.cls-1{fill:none;stroke:#454547;stroke-miterlimit:10;}</style></defs><line class="cls-1" x1="0" y1="3.74" x2="12.6" y2="3.74"/><line class="cls-1" x1="11.02" y1="3.74" x2="7.65" y2=".35"/><line class="cls-1" x1="7.65" y1="7.12" x2="11.02" y2="3.74"/></svg>'
                    )
                    no-repeat right 11px center / 13px auto;
                @media (hover: hover) {
                    &:hover {
                        background-position: right 6px center;
                    }
                }
            }
            ._tel {
                flex-shrink: 0;
                display: inline-block;
                padding-left: 27px;
                font-size: calc(24 / 16 * $font-size-base);
                font-family: 'Roboto', $font-family-sans-serif;
                font-feature-settings: 'palt';
                letter-spacing: 0.12em;
                line-height: 1;
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.97 25.97"><defs><style>.cls-1{fill:#ccc45c;}.cls-1,.cls-2{stroke-width:0px;}.cls-2{fill:#fff;}</style></defs><circle class="cls-1" cx="12.99" cy="12.99" r="12.99"/><path class="cls-2" d="M10.18,7.32l1.25,1.25c.18.18.17.49-.03.69l-.51.5c-.18.18-.21.46-.07.64,0,0,.17,1.16,1.54,2.53,1.39,1.39,2.49,1.5,2.49,1.5.19.14.46.11.64-.07l.5-.5c.2-.2.51-.21.69-.03l1.25,1.25c.18.18.17.49-.03.69,0,0-.07.07-.82.82-.87.87-4.06-.8-5.85-2.58-1.78-1.78-3.45-4.97-2.58-5.85.75-.75.82-.82.82-.82.2-.2.51-.21.69-.03Z"/></svg>'
                    )
                    no-repeat left center / 18px auto;
                @include media-down(md) {
                }
            }
        }
    }
}
.renovetion-faq {
    padding-inline: map-get($spacers, 3);
    padding-bottom: 60px;
    background-color: #efefef;
    &__inner {
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
    }
    &__title {
        @include c-title-local(center);
        margin-bottom: 75px;
        @include media-down(md) {
            margin-bottom: 25px;
        }
    }
}
._accordion {
    &__item {
        padding: 40px 40px 40px 95px;
        background-color: #fff;
        @include media-down(lg) {
            padding: 22px 18px;
        }
        &:not(:last-of-type) {
            margin-bottom: 15px;
        }
        ._icon {
            position: absolute;
            left: 0;
            font-size: calc(21 / 16 * $font-size-base);
            font-family: 'Noto Sans JP', sans-serif;
            font-optical-sizing: auto;
            font-weight: 500;
            font-style: normal;
            letter-spacing: 0.08em;
            line-height: 1;
            @include media-down(lg) {
                font-size: calc(20 / 16 * $font-size-base);
            }
        }
    }
    &__btn {
        position: relative;
        cursor: pointer;
        padding-left: 45px;
        padding-right: 85px;
        font-size: calc(17 / 16 * $font-size-base);
        font-weight: bold;
        letter-spacing: 0.08em;
        line-height: calc(22 / 16);
        @include media-down(lg) {
            padding-left: 30px;
            padding-right: 30px;
            font-size: calc(16 / 16 * $font-size-base);
        }
        ._icon {
            display: flex;
            align-items: center;
            top: -3px;
            bottom: 0;
            margin: auto;
            color: #ccc45c;
            @include media-down(md) {
                top: -7px;
            }
        }
        ._btn-icon {
            position: absolute;
            top: -2px;
            right: 0;
            width: 14px;
            height: 14px;
            img {
                transition: $transition-base;
                transform-origin: center;
                .is-open & {
                    transform: rotate(45deg);
                }
            }
        }
    }
    &__content {
        position: relative;
        padding-left: 45px;
        @include media-down(lg) {
            padding-left: 30px;
        }
        ._icon {
            color: #373737;
        }
        ._body {
            margin-top: 20px;
            color: #373737;
            @include media-down(md) {
                margin-top: 10px;
            }
        }
        p {
            margin-bottom: 15px;
            font-size: calc(14 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            letter-spacing: 0.08em;
            line-height: calc(28 / 14);
            @include media-down(md) {
                line-height: calc(26 / 14);
            }
        }
        b {
            display: block;
        }
        dl {
            margin-bottom: 25px;
            padding: 20px 35px;
            font-size: calc(13 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            letter-spacing: 0.08em;
            line-height: calc(26 / 13);
            border: solid 1px #d6d6d6;
            @include media-down(md) {
                padding: 10px 20px;
            }
            dt,
            dd {
                margin: 0;
            }
        }
    }
}
