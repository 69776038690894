@keyframes up_down_line {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-8px);
    }
}

.rwd002-footerSocial {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 90px;
    margin-bottom: 90px;
    @include media-up(lg) {
        margin-top: 60px;
        margin-bottom: 60px;
    }
    &__title {
        letter-spacing: 0.16em;
        margin-right: 1em;
        font-family: 'Lato', $font-family-sans-serif;
    }
    &__btn {
        @extend .btn;
        font-size: 1.8em;
        margin: 0 0.1em;
        line-height: 1;
        transition: $transition-base;
    }
    &__btn.is-fb {
        &:hover,
        &:focus {
            color: $sns-facebook-bg;
        }
    }
    &__btn.is-tw {
        &:hover,
        &:focus {
            color: $sns-twitter-bg;
        }
    }
    &__btn.is-line {
        &:hover,
        &:focus {
            color: $sns-line-bg;
        }
    }
}

.rwd002-pagetop {
    transition: $transition-base;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    @include font-en();
    font-size: calc(12 / 16 * $font-size-base);
    color: $body-color;
    text-decoration: none;
    letter-spacing: 0.16em;
    line-height: 1;
    &:before {
        transition: $transition-base;
        content: '';
        display: block;
        margin-bottom: 10px;
        width: 48px;
        height: 13px;
        background: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.97 13.09"><defs><style>.cls-1{fill:none;stroke:#373737;stroke-miterlimit:10;}</style></defs><path class="cls-1" d="M.22,12.64L24.48.56l24.26,12.08"/></svg>'
            )
            no-repeat center / contain;
    }
    &:hover,
    &:focus {
        text-decoration: none;
        color: #bb9800;
        &:before {
            transform: translateY(-7px);
            background: svgUrlFunc(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.97 13.09"><defs><style>.cls-1{fill:none;stroke:#bb9800;stroke-miterlimit:10;}</style></defs><path class="cls-1" d="M.22,12.64L24.48.56l24.26,12.08"/></svg>'
                )
                no-repeat center / contain;
        }
    }
    &__wrap {
        width: 100%;
        z-index: 10;
        display: flex;
        justify-content: center;
        @include media-up(lg) {
        }
        @include media-down(lg) {
        }
    }
}
.footer-contact {
    padding: 130px map-get($spacers, 3) 105px;
    background-color: #efefef;
    @include media-down(md) {
        padding-top: 50px;
        padding-bottom: 45px;
    }
    &__title {
        @include c-block-title(center);
        margin-bottom: 35px;
        @include media-down(md) {
            margin-bottom: 25px;
        }
    }
    &__text {
        margin-bottom: 30px;
        font-size: calc(13 / 16 * $font-size-base);
        font-feature-settings: 'palt';
        text-align: center;
        letter-spacing: 0.16em;
        line-height: calc(26 / 13);
        @include media-down(md) {
            margin-bottom: 25px;
        }
    }
    &__body {
        display: flex;
        @include media-up(md) {
            justify-content: center;
            gap: 15px;
        }
        @include media-down(md) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    &__btn {
        transition: $transition-base;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 340px;
        height: 85px;
        @include font-en();
        font-size: calc(16 / 16 * $font-size-base);
        font-weight: 600;
        text-decoration: none !important;
        color: #fff;
        letter-spacing: 0.16em;
        background-color: $primary;
        border: solid 1px $primary;
        border-radius: 50px;
        @media (hover: hover) {
            &:hover {
                color: $primary;
                background-color: transparent;
            }
        }
        @include media-down(md) {
            width: 260px;
            height: 55px;
        }
    }
    &__tel {
        @include media-up(md) {
            padding-left: 18px;
        }
        ._tel {
            display: inline-block;
            margin-top: 24px;
            padding-left: 37px;
            font-size: calc(36 / 16 * $font-size-base);
            font-family: 'Roboto', $font-family-sans-serif;
            font-feature-settings: 'palt';
            letter-spacing: 0.08em;
            line-height: 1;
            background: svgUrlFunc(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.97 25.97"><defs><style>.cls-1{fill:#ccc45c;}.cls-1,.cls-2{stroke-width:0px;}.cls-2{fill:#fff;}</style></defs><circle class="cls-1" cx="12.99" cy="12.99" r="12.99"/><path class="cls-2" d="M10.18,7.32l1.25,1.25c.18.18.17.49-.03.69l-.51.5c-.18.18-.21.46-.07.64,0,0,.17,1.16,1.54,2.53,1.39,1.39,2.49,1.5,2.49,1.5.19.14.46.11.64-.07l.5-.5c.2-.2.51-.21.69-.03l1.25,1.25c.18.18.17.49-.03.69,0,0-.07.07-.82.82-.87.87-4.06-.8-5.85-2.58-1.78-1.78-3.45-4.97-2.58-5.85.75-.75.82-.82.82-.82.2-.2.51-.21.69-.03Z"/></svg>'
                )
                no-repeat left center / 26px auto;
            @include media-down(md) {
                margin-top: 22px;
                padding-left: 33px;
                font-size: calc(32 / 16 * $font-size-base);
                background-size: 23px auto;
            }
        }
        ._text {
            margin: 5px 0 0;
            font-size: calc(10 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            letter-spacing: 0.16em;
            @include media-up(md) {
                padding-left: 37px;
            }
            @include media-down(md) {
                text-align: center;
            }
        }
    }
}
.rwd002-footer {
    position: relative;
    color: $white;
    background: url(/assets/img/webp/back-footer@2x.webp) no-repeat top center /
        cover;
    @include media-up(lg) {
        padding: 60px map-get($spacers, 5) 40px;
    }
    @include media-down(lg) {
        padding: 50px map-get($spacers, 4) 15px;
    }
    @include media-down(md) {
        padding: 50px map-get($spacers, 3) 15px;
    }
    @include media-down(sm) {
        background-image: url(/assets/img/webp/back-footer_sp@2x.webp);
    }

    &__inner {
        display: flex;
        max-width: 1230px;
        margin: 0 auto;
        @include media-up(lg) {
        }
        @include media-down(lg) {
            flex-direction: column;
            align-items: stretch;
        }
    }

    &__info {
        @include media-up(lg) {
            flex-grow: 1;
            padding-right: map-get($spacers, 3);
        }
        @include media-down(lg) {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 25px;
            padding-bottom: 32px;
            border-bottom: solid 1px rgba(255, 255, 255, 0.3);
        }
    }
    &__companyBody {
        margin-bottom: 27px;
        @include media-down(lg) {
            margin-bottom: 20px;
            text-align: center;
        }
    }
    &__companyLogo {
        margin-bottom: 82px;
        @include media-down(lg) {
            margin-bottom: 35px;
        }
    }
    &__companyName {
        margin-bottom: 8px;
        font-size: calc(12 / 16 * $font-size-base);
        font-feature-settings: 'palt';
        color: #fff;
        letter-spacing: 0.16em;
    }

    &__address {
        margin: 0;
        font-size: calc(12 / 16 * $font-size-base);
        font-feature-settings: 'palt';
        color: #fff;
        letter-spacing: 0.16em;
    }

    &__link-con {
        display: flex;
        @include media-up(sm) {
            align-items: flex-end;
        }
        @include media-down(sm) {
            flex-direction: column;
            align-items: center;
            gap: 18px;
        }
        ._link-btn {
            transition: $transition-base;
            display: flex;
            align-items: center;
            width: 177px;
            height: 40px;
            padding-left: 23px;
            font-size: calc(12 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            text-decoration: none !important;
            color: #fff;
            letter-spacing: 0.16em;
            background: svgUrlFunc(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.61 7.4"><defs><style>.cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;}</style></defs><line class="cls-1" x1="0" y1="3.7" x2="14.61" y2="3.7"/><line class="cls-1" x1="12.05" y1="3.7" x2="8.71" y2=".35"/><line class="cls-1" x1="8.71" y1="7.05" x2="12.05" y2="3.7"/></svg>'
                )
                no-repeat right 14px center / 15px auto;
            border: solid 1px #fff;
            border-radius: 30px;
            @media (hover: hover) {
                &:hover {
                    color: #696265;
                    background: svgUrlFunc(
                            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.61 7.4"><defs><style>.cls-1{fill:none;stroke:#696265;stroke-miterlimit:10;}</style></defs><line class="cls-1" x1="0" y1="3.7" x2="14.61" y2="3.7"/><line class="cls-1" x1="12.05" y1="3.7" x2="8.71" y2=".35"/><line class="cls-1" x1="8.71" y1="7.05" x2="12.05" y2="3.7"/></svg>'
                        )
                        no-repeat right 14px center / 15px auto #fff;
                }
            }
            @include media-down(sm) {
            }
        }
        ._sns-btn {
            display: flex;
            align-items: center;
            @include media-up(sm) {
                margin-left: 40px;
                margin-bottom: 6px;
            }
            ._link {
                position: relative;
                display: inline-block;
                padding: 3px 0;
                @include font-en();
                font-size: calc(12 / 16 * $font-size-base);
                font-weight: 600;
                text-decoration: none !important;
                letter-spacing: 0.16em;
                color: #fff;
                &:not(:last-of-type) {
                    margin-right: 28px;
                    &::after {
                        content: '/';
                        position: absolute;
                        right: -16px;
                        color: #fff;
                    }
                }
                &::before {
                    transition: transform 0.3s;
                    content: '';
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    margin: auto;
                    transform-origin: right top;
                    transform: scale(0, 1);
                    background: #fff;
                }
                @media (hover: hover) {
                    &:hover {
                        &::before {
                            transform-origin: left top;
                            transform: scale(1, 1);
                        }
                    }
                }
            }
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
        @include media-up(lg) {
            position: absolute;
            bottom: 30px;
            right: 30px;
        }
        @include media-down(lg) {
            justify-content: center;
            margin-top: 25px;
        }
    }

    &__copyright,
    &__login {
        display: block;
        text-align: center;
        @include font-en();
        font-size: calc(10 / 16 * $font-size-base);
        font-weight: 200;
        letter-spacing: 0.08em;
        text-indent: 0.08em;
        color: #fff;
        font-feature-settings: 'palt';
    }

    &__copyright {
        @include media-down(md) {
            // margin-left: -1.3em;
            // margin-right: -1.3em;
        }
    }

    &__login {
        // margin-top: 1em;
    }
}

.rwd002-footerNav {
    @include media-up(lg) {
    }
    @include media-down(lg) {
        display: flex;
        flex-wrap: wrap;
    }
    @include media-down(md) {
        display: block;
    }
    &__list,
    &__sublist {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    &__list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px 20px;
        margin-bottom: 10px;
        font-size: calc(13 / 16 * $font-size-base);
        color: #fff;
        @include media-down(md) {
            margin: 0 0 10px;
        }
    }
    &__sublist {
        display: flex;
        align-items: center;
        font-size: calc(11 / 16 * $font-size-base);
        color: #fff;

        @include media-down(md) {
        }
    }
}

.rwd002-footerNav-item {
    display: flex;
    flex-wrap: wrap;
    @include media-down(md) {
    }
    &__link {
        transition: $transition-base;
        position: relative;
        display: inline-block;
        padding: 5px 0;
        letter-spacing: 0.08em;
        font-weight: bold;
        text-decoration: none !important;
        line-height: 1;
        &::before {
            transition: transform 0.3s;
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            left: 0;
            bottom: 0;
            margin: auto;
            transform-origin: right top;
            transform: scale(0, 1);
            background: #fff;
        }
        @media (hover: hover) {
            &:hover {
                &::before {
                    transform-origin: left top;
                    transform: scale(1, 1);
                }
            }
        }
        @include media-down(md) {
        }
    }
}

.rwd002-footerNav-subitem {
    &__link {
        transition: $transition-base;
        position: relative;
        display: inline-block;
        font-feature-settings: 'palt';
        padding: 5px 0;
        text-decoration: none !important;
        color: #fff;
        letter-spacing: 0.08em;
        line-height: 1;
        &::before {
            transition: transform 0.3s;
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            left: 0;
            bottom: 0;
            margin: auto;
            transform-origin: right top;
            transform: scale(0, 1);
            background: #fff;
        }
        @media (hover: hover) {
            &:hover {
                &::before {
                    transform-origin: left top;
                    transform: scale(1, 1);
                }
            }
        }
    }
    .slash {
        transform: translateY(1px);
        display: inline-block;
        margin-inline: 8px;
    }
}

.rwd002-footer-subnav {
    @include media-up(md) {
        display: none;
    }
    &__tgl {
        @extend .btn;

        &:focus {
            box-shadow: none;
        }

        @include media-up(md) {
            display: none;
        }
        @include media-down(md) {
            position: relative;
            flex-basis: 40px;
            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: calc(50% - 5px);
                width: 10px;
                height: 1px;
                background-color: darken($white, 50%);
                transition: transform 0.2s ease-in-out;
            }

            &:after {
                transform: rotate(-90deg);
            }

            &.is-active {
                &:before {
                    transform: rotate(180deg);
                }
                &:after {
                    transform: rotate(0deg);
                }
            }
        }
    }
    &__list {
        list-style: none;
        @include media-down(md) {
            padding-left: 1.7em;
            padding-bottom: 1em;
        }
    }
}

.rwd002-footer-subnav-item {
    &__link {
        @include media-down(md) {
            display: block;
            padding: 0.2em 0;
        }
    }
}

.rwd002-footer-search {
    margin-top: 2em;
    @include media-down(lg) {
        width: 100%;
        margin-top: 1.5em;
        margin-bottom: 3em;
    }
    &__inner {
        position: relative;
        max-width: 320px;
        @include media-down(md) {
            margin-left: -0.8em;
            margin-right: -0.8em;
        }
    }
    &__input {
        @extend .form-control;
        padding-right: 2.2em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        background-color: tint($dark, 10%);
        transition: $transition-base;
        border-width: 0;
    }
    &__btn {
        @extend .btn;
        position: absolute;
        top: 0;
        bottom: 0;
        right: calc(100% - 2.2em);
        width: 2.2em;
        padding: 0 0.5em;
        pointer-events: none;
        transition: $transition-base;
        > svg {
            width: 1em;
            height: 100%;
            fill: tint($dark, 30%);
        }
    }
    &__btn:focus,
    &__input:focus + &__btn {
        pointer-events: auto;
        right: 0;
    }
}
