.is--about {
    .rwd002-container {
        margin-top: 0;
    }
    .page-link {
        margin-top: 115px;
        @include media-down(md) {
            margin-top: 10px;
        }
    }
}
.about-headline {
    ._main-img {
        width: 100%;
        height: auto;
    }
}
.about-strength {
    &__container {
        padding-inline: 30px;
        @include media-down(md) {
            padding-inline: map-get($spacers, 3);
        }
    }
    &__title {
        @include c-title-local(center);
        margin-bottom: 125px;
        @include media-down(md) {
            margin-bottom: 30px;
        }
    }
    &__item {
        display: flex;
        width: 100%;
        max-width: 1340px;
        margin: 0 auto 100px;
        @include media-up(lg) {
            align-items: center;
        }
        @include media-down(lg) {
            flex-direction: column;
        }
        &:nth-of-type(odd) {
            ._image {
                @include media-up(lg) {
                    display: flex;
                    justify-content: flex-end;
                }
            }
            ._body {
                padding-left: 90px;
                @media (max-width: 1070px) {
                    padding-left: 30px;
                }
                @include media-down(lg) {
                    padding-left: 0;
                }
            }
        }
        &:nth-of-type(even) {
            @include media-up(lg) {
                flex-direction: row-reverse;
            }
            ._textbox {
                margin-left: auto;
                margin-right: 70px;
                @media (max-width: 1070px) {
                    margin-right: 0;
                    padding-right: 30px;
                }
                @include media-down(lg) {
                    padding-right: 0;
                }
            }
        }
        &:first-of-type {
            @include media-down(lg) {
                margin-bottom: 35px;
            }
            ._abs-img {
                @include media-up(lg) {
                    position: absolute;
                    top: -162px;
                    right: -18px;
                }
                @include media-down(lg) {
                    display: block;
                    width: 223px;
                    height: auto;
                    margin: 25px auto 0;
                }
            }
        }
        &:nth-of-type(2) {
            @include media-down(lg) {
                margin-bottom: 50px;
            }
        }
        &:nth-of-type(3) {
            align-items: flex-start;
            margin-bottom: 40px;
            @include media-down(lg) {
                margin-bottom: 80px;
            }
            ._image {
                @include media-up(lg) {
                    padding-right: 30px;
                }
            }
            ._body {
                @include media-up(lg) {
                    padding-top: 70px;
                }
                @include media-down(lg) {
                    margin-top: -35px !important;
                }
            }
        }
        ._image {
            width: calc(50% + 7px);
            @include media-down(lg) {
                width: 100%;
                max-width: 500px;
                margin-inline: auto;
            }
            img {
                max-width: 100%;
                height: auto;
            }
        }
        ._body {
            width: calc(50% - 7px);
            @include media-down(lg) {
                width: 100%;
                margin-top: 25px;
            }
            ._textbox {
                position: relative;
                width: 100%;
                @include media-up(lg) {
                    max-width: 445px;
                }
            }
            ._point {
                @include font-en();
                margin-bottom: 13px;
                font-size: calc(12 / 16 * $font-size-base);
                font-weight: 600;
                color: #838081;
                letter-spacing: 0.16em;
                line-height: calc(19 / 12);
                @include media-down(lg) {
                    margin-bottom: 11px;
                    text-align: center;
                }
            }
            ._title {
                position: relative;
                margin-bottom: 40px;
                padding-bottom: 15px;
                font-size: calc(26 / 16 * $font-size-base);
                font-feature-settings: 'palt';
                font-weight: bold;
                color: #838081;
                letter-spacing: 0.08em;
                line-height: 1.6;
                @include media-down(lg) {
                    margin-bottom: 20px;
                    text-align: center;
                }
                @include media-down(md) {
                    margin-bottom: 22px;
                    padding-bottom: 12px;
                    font-size: calc(22 / 16 * $font-size-base);
                }
                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 62px;
                    height: 1px;
                    background-color: #838081;
                    @include media-down(lg) {
                        right: 0;
                        margin: auto;
                    }
                }
            }
            ._text {
                margin: 0;
                font-size: calc(15 / 16 * $font-size-base);
                font-feature-settings: 'palt';
                color: #454547;
                letter-spacing: 0.08em;
                line-height: calc(32 / 15);
                ._marker {
                    transition: background-size 0.8s;
                    padding: 4px 0;
                    background-image: linear-gradient(#e6e1ad, #e6e1ad);
                    background-size: 0 100%;
                    background-position: 0 100%;
                    background-repeat: no-repeat;
                }
            }
            .is-active {
                ._marker {
                    background-size: 100% 100%;
                }
            }
        }
    }
}
.about-history {
    @include media-up(md) {
        padding-inline: 30px;
    }
    &__title {
        @include c-title-local(center);
        margin-bottom: 50px;
        @include media-down(md) {
            margin-bottom: 25px;
        }
    }
    &__container {
        padding: 0 map-get($spacers, 3) 120px;
        background: url(/assets/img/webp/about/back-history@2x.webp) no-repeat
            top center / cover;
        @include media-down(lg) {
            padding-bottom: 50px;
        }
        @include media-down(sm) {
            background-image: url(/assets/img/webp/about/back-history_sp@2x.webp);
        }
    }
    &__body {
        display: flex;
        width: 100%;
        max-width: 950px;
        margin-inline: auto;
        @include media-up(lg) {
            align-items: flex-start;
        }
        @include media-down(lg) {
            flex-direction: column;
            align-items: center;
            gap: 35px;
        }
    }
    ._logo {
        @include media-up(lg) {
            width: 400px;
            flex-shrink: 0;
            margin-top: 27px;
        }
        @include media-down(sm) {
            img {
                width: 257px;
                height: auto;
            }
        }
    }
    ._textbox {
        @include media-down(sm) {
            width: 100%;
        }
        p {
            margin: 0;
            font-size: calc(15 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            color: #454547;
            letter-spacing: 0.08em;
            line-height: calc(32 / 15);
            @include media-down(md) {
                letter-spacing: 0.06em;
                line-height: calc(28 / 15);
            }
        }
    }
}
.about-message {
  overflow: hidden;
    margin-bottom: 125px;
    padding-inline: map-get($spacers, 3);
    @include media-down(md) {
        margin-bottom: 50px;
    }
    &__inner {
        width: 100%;
        max-width: 970px;
        margin-inline: auto;
    }
    &__title {
        @include c-title-local(center);
        margin-bottom: 85px;
        @include media-down(md) {
            margin-bottom: 80px;
        }
    }
    &__container {
        display: flex;
        gap: 80px;
        @include media-up(lg) {
            justify-content: space-between;
        }
        @include media-down(lg) {
            flex-direction: column;
            gap: 30px;
        }
        @include media-down(md) {
        }
    }
    &__image {
        position: relative;
        @include media-up(lg) {
            flex-shrink: 0;
            width: 334px;
        }
        @include media-down(lg) {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }
        figure {
            position: relative;
            margin: 0;
            @include media-down(md) {
                width: 231px;
            }
            &::before {
                content: '';
                position: absolute;
                z-index: -1;
                top: -88px;
                left: -70px;
                width: 251px;
                height: 251px;
                background-color: #ccc45c;
                border-radius: 50%;
                @include media-down(lg) {
                    top: -60px;
                    left: -50px;
                    width: 174px;
                    height: 174px;
                }
            }
        }
        img {
            display: block;
            max-width: 100%;
            height: auto;
        }
        p {
            font-feature-settings: 'palt';
            text-align: center;
        }
        ._position {
            margin: 32px 0 13px;
            font-size: calc(13 / 16 * $font-size-base);
            letter-spacing: 0.12em;
            @include media-down(lg) {
                margin: 25px 0 12px;
            }
        }
        ._name {
            margin: 0;
            font-size: calc(26 / 16 * $font-size-base);
            letter-spacing: 0.12em;
        }
    }
    &__body {
        @include media-up(lg) {
            padding-top: 40px;
        }
        p {
            font-feature-settings: 'palt';
        }
        ._text {
            font-size: calc(15 / 16 * $font-size-base);
            color: #454547;
            letter-spacing: 0.08em;
            line-height: calc(32 / 15);
        }
        ._small-text {
            margin-top: 35px;
            margin-bottom: 0;
            padding-top: 25px;
            font-size: calc(15 / 16 * $font-size-base);
            color: #838081;
            letter-spacing: 0.08em;
            line-height: calc(25 / 12);
            border-top: solid 1px #d6d6d6;
        }
    }
}
.about-company {
    padding-inline: map-get($spacers, 3);
    &__image {
        width: 100%;
        height: 452px;
        background-attachment: fixed;
        background-image: url(/assets/img/webp/about/pho-company@2x.webp);
        background-position: center;
        background-size: cover;
        @media (max-width: 1200px) {
            background-attachment: inherit;
        }
        @include media-down(lg) {
            height: 350px;
        }
        @include media-down(sm) {
            height: 200px;
            background-image: url(/assets/img/webp/about/pho-company_sp@2x.webp);
        }
    }
    &__inner {
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
    }
    &__title {
        @include c-title-local(center);
        margin-bottom: 70px;
        @include media-down(md) {
            margin-bottom: 30px;
        }
    }
    &__table {
        @include table-typeA();
        margin-bottom: 35px;
        @include media-down(md) {
            margin-bottom: 25px;
        }
    }
    &__btnwrap {
    }
    &__btn {
        @include c-button-open();
    }
    &__bnr {
        transition: $transition-base;
        display: block;
        margin-top: 35px;
        @include media-down(md) {
            margin-top: 10px;
        }
        img {
            width: 100%;
            height: auto;
        }
        @media (hover: hover) {
            &:hover {
                opacity: 0.8;
            }
        }
    }
}
.page-link {
    padding-inline: map-get($spacers, 3);
    &__inner {
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
    }
    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        @include media-down(md) {
            grid-template-columns: 1fr;
            gap: 10px;
        }
    }
    &__item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: 267px;
        padding-bottom: 45px;
        background-color: #efefef;
        border-radius: 5px;
        @include media-down(md) {
            height: 215px;
            &:last-of-type {
                img {
                    width: 236px;
                }
            }
        }
        img {
            height: auto;
        }
        &::before {
            transition: $transition-base;
            content: '';
            position: absolute;
            bottom: 15px;
            right: 15px;
            width: 20px;
            height: 11px;
            margin: auto;
            background: svgUrlFunc(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.32 11.72"><defs><style>.cls-1{fill:none;stroke:#454547;stroke-miterlimit:10;}</style></defs><line class="cls-1" x1="0" y1="5.86" x2="68.32" y2="5.86"/><line class="cls-1" x1="65.76" y1="5.86" x2="60.27" y2=".35"/><line class="cls-1" x1="60.27" y1="11.37" x2="65.76" y2="5.86"/></svg>'
                )
                no-repeat right center / auto 100%;
            @include media-down(md) {
                background-size: auto 100% !important;
            }
        }
        @media (hover: hover) {
            &:hover {
                &::before {
                    transform: translateX(5px);
                    background: svgUrlFunc(
                            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.32 11.72"><defs><style>.cls-1{fill:none;stroke:#beb42f;stroke-miterlimit:10;}</style></defs><line class="cls-1" x1="0" y1="5.86" x2="68.32" y2="5.86"/><line class="cls-1" x1="65.76" y1="5.86" x2="60.27" y2=".35"/><line class="cls-1" x1="60.27" y1="11.37" x2="65.76" y2="5.86"/></svg>'
                        )
                        no-repeat right center / auto 100%;
                }
            }
        }
    }
}
