.is--home {
    .rwd002-container {
        margin-top: 0;
    }
}
.mainvisual {
    overflow: hidden;
    position: relative;
    width: 100%;
    @include media-up(lg) {
    }
    @include media-down(lg) {
    }
    &__catch {
        h2 {
            margin: 0;
        }
        @include media-up(lg) {
            display: flex;
            align-items: flex-end;
            height: 220px;
            padding-bottom: 40px;
            padding-left: map-get($spacers, 5);
        }
        @include media-down(lg) {
            padding: 20px map-get($spacers, 3) 50px;
        }
        img {
            max-width: 100%;
            height: auto;
            @include media-down(md) {
                width: 306px;
            }
        }
    }
    &__eng {
        position: absolute;
        z-index: 2;
        top: 190px;
        right: 70px;
        @include media-down(md) {
            top: 136px;
            right: 18px;
        }
        img {
            @include media-down(md) {
                width: 154px;
                height: auto;
            }
        }
    }
    &__scroll {
        position: absolute;
        bottom: 160px;
        right: 62px;
        display: flex;
        align-items: flex-end;
        writing-mode: vertical-rl;
        column-gap: 1px;
        @include font-en();
        font-size: calc(11 / 16 * $font-size-base);
        font-weight: 600;
        color: #373737;
        letter-spacing: 0.11em;
        line-height: 1;
        z-index: 1;
        @include media-down(md) {
            bottom: 140px;
            right: 9px;
        }
        span {
            transform: translate(-5px, -7px);
        }
        .bar {
            position: relative;
            overflow: hidden;
            width: 1px;
            height: 160px;
            &::before,
            &:after {
                content: '';
                position: absolute;
                top: 0;
            }
            &::before {
                height: 100%;
                width: 1px;
                background: #c3c3c3;
            }
            &:after {
                width: 1px;
                height: 44px;
                margin: auto;
                background: #ccc45c;
                animation: scroll_line 1200ms ease-in-out infinite;
            }
        }
    }
}
@keyframes scroll_line {
    from {
        transform: translateY(-44px);
    }
    to {
        transform: translateY(204px);
    }
}
.mainvisual-slider__wrapper {
    overflow: hidden;
    width: calc(100% - 100px);
    height: calc((100svh - 340px) + 160px);
    border-radius: 0 5px 0 0;
    @include media-down(lg) {
        height: calc((100svh - 285px) + 160px);
    }
    @include media-down(md) {
        width: calc(100% - 40px);
        height: calc(100svh - 230px);
        border-radius: 0 5px 5px 0;
    }
    @include media-down(sm) {
        aspect-ratio: 335 / 476;
        height: 100%;
    }
}
.mainvisual-slider {
    height: 100%;
    @include media-down(lg) {
    }
    @include media-down(sm) {
    }
    &__item img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        @include media-down(sm) {
            height: 100%;
        }
    }

    .swiper-slide-active img,
    .swiper-slide-duplicate-active img,
    .swiper-slide-prev img {
        transform: scale(1);
        animation: zoomOut 10s linear 0s 1 normal both;
    }
}
@keyframes zoomOut {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.1);
    }
}
.mainvisual-headline {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;
    width: 100%;
    height: 120px;
    padding: 10px 0 13px 55px;
    background-color: #fff;
    @include media-up(md) {
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 540px;
    }
    @include media-up(md) {
        justify-content: flex-end;
        margin-right: 100px;
    }
    @include media-down(md) {
        height: auto;
        padding: 35px map-get($spacers, 3) 0;
    }
    &__title {
        @include font-en();
        font-size: calc(14 / 16 * $font-size-base);
        font-weight: 700;
        letter-spacing: 0.16em;
    }
    &__article {
        width: 100%;
    }
    &__link {
        position: relative;
        white-space: nowrap;
        width: 100%;
        text-decoration: none !important;
        &:hover,
        &:focus {
            .subject {
                text-decoration: underline !important;
            }
        }
    }
    .date-cate {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: calc(11 / 16 * $font-size-base);
        color: #7a7072;
        letter-spacing: 0.08em;
        line-height: 1;
        .date {
            @include font-en();
            font-weight: 600;
            margin-right: 6px;
            padding-right: 7px;
            border-right: solid 1px #7a7072;
        }
        .cate {
        }
    }

    .subject {
        transition: $transition-base;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: calc(13 / 16 * $font-size-base);
        letter-spacing: 0.13em;
        margin: 0;
        color: #373737;
        @include media-down(lg) {
        }
        @include media-down(sm) {
        }
    }
    &__btn {
        @include c-button-view-border();
        @include media-up(md) {
            position: absolute;
            top: 25px;
            right: 15px;
        }
        @include media-down(md) {
            margin-top: 5px;
            margin-left: auto;
        }
    }
}
.block-aboutus {
    position: relative;
    z-index: 2;
    padding: 135px 0 135px map-get($spacers, 3);
    @include media-down(md) {
        padding: 38px 0 90px map-get($spacers, 3);
    }
    ._main-image {
        max-width: 100%;
        height: auto;
        @include media-down(md) {
            width: 100%;
            max-width: 400px;
            float: right;
        }
    }
    ._sub-image {
        position: absolute;
        left: 0;
        bottom: -80px;
        @include media-down(lg) {
            display: none;
        }
    }
    &__body {
        display: flex;
        justify-content: flex-start;
        flex-direction: row-reverse;
        gap: 90px;
        width: 100%;
        max-width: 1080px;
        margin-inline: auto;
        @media (max-width: 950px) {
            gap: 40px;
        }
        @include media-down(lg) {
            gap: 0;
        }
        @include media-down(md) {
            flex-direction: column;
        }
    }
    &__title {
        @include c-block-title();
        margin-bottom: 70px;
        @include media-down(md) {
            margin-bottom: 25px;
        }
    }
    ._textbox {
        flex-shrink: 0;
        width: 100%;
        max-width: 432px;
        padding-top: 60px;
        @include media-down(md) {
            max-width: 100%;
            padding-top: 38px;
            padding-right: map-get($spacers, 3);
        }
        ._lead {
            margin-bottom: 30px;
            font-size: calc(29 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            letter-spacing: 0.16em;
            line-height: calc(51 / 29);
            @include media-down(md) {
                margin-bottom: 15px;
            }
            @include media-down(sm) {
                font-size: calc(24 / 16 * $font-size-base);
                line-height: calc(42 / 24);
            }
        }
        ._text {
            margin-bottom: 35px;
            font-size: calc(15 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            letter-spacing: 0.08em;
            line-height: calc(36 / 15);
            @include media-down(sm) {
                letter-spacing: 0.06em;
                line-height: calc(32 / 15);
            }
        }
    }
    &__btnwrap {
        display: flex;
        justify-content: flex-end;
    }
    &__btn {
        @include c-button-view();
    }
}
.block-service {
    position: relative;
    z-index: 1;
    padding-top: 100px;
    @include media-down(xl) {
        padding-inline: map-get($spacers, 3);
    }
    @include media-down(md) {
        padding-top: 85px;
    }
    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #efefef;
        @include media-up(md) {
            width: calc(100% - 100px);
            border-radius: 0 5px 0 0;
        }
    }
    [class*='_abs-image'] {
        position: absolute;
        height: auto;
    }
    ._abs-image1 {
        top: -106px;
        right: -7px;
        @include media-down(md) {
            top: -35px;
            right: 23px;
            width: 137px;
        }
    }
    ._abs-image2 {
        bottom: 19px;
        left: -75px;
        @include media-down(xl) {
            bottom: -42px;
            left: 25px;
        }
        @include media-down(md) {
            bottom: -78px;
            left: -12px;
            width: 81px;
        }
    }
    &__title {
        @include c-block-title(center);
        margin-bottom: 45px;
        @include media-down(md) {
            margin-bottom: 25px;
        }
    }
    &__item {
        position: relative;
        display: flex;
        width: calc(100% - 185px);
        text-decoration: none !important;
        background-color: #fff;
        @include media-down(xl) {
            width: 100%;
        }
        @include media-up(md) {
            height: 397px;
        }
        @include media-down(md) {
            display: block;
        }
        &:not(:last-of-type) {
            margin-bottom: 50px;
            @include media-down(md) {
                margin-bottom: 20px;
            }
        }
        &:nth-of-type(odd) {
            @include media-up(lg) {
                border-radius: 0 5px 5px 0;
            }
            ._title {
                img {
                    @include media-down(md) {
                        width: 197px;
                    }
                }
            }
        }
        &:nth-of-type(even) {
            flex-direction: row-reverse;
            margin-left: auto;
            @include media-up(lg) {
                border-radius: 5px 0 0 5px;
            }
            ._title {
                img {
                    @include media-down(md) {
                        width: 224px;
                    }
                }
            }
        }
        ._image {
            overflow: hidden;
            width: calc(100% - 495px);
            height: 100%;
            @include media-down(xl) {
                width: 50%;
            }
            @include media-down(md) {
                width: 100%;
                height: 300px;
            }
            @include media-down(sm) {
                height: 177px;
            }
            img {
                width: 100%;
                height: auto;
                @include media-up(sm) {
                    transition: $transition-base;
                    object-fit: cover;
                    object-position: center;
                    height: 100%;
                }
            }
        }
        ._tlbox {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            width: 495px;
            height: 100%;
            @include media-down(xl) {
                width: 50%;
            }
            @include media-down(md) {
                width: 100%;
            }
        }
        ._title {
            margin: 0 auto 65px;
            @include media-down(md) {
                margin-top: 35px;
                margin-bottom: 25px;
            }
            img {
                height: auto;
            }
        }
        @media (hover: hover) {
            &:hover {
                ._image {
                    img {
                        transform: scale(1.1);
                    }
                }
                .block-service__btn {
                    color: #bb9800;
                    &::before {
                        transform: translateX(5px);
                        background: svgUrlFunc(
                                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.32 11.72"><defs><style>.cls-1{fill:none;stroke:#beb42f;stroke-miterlimit:10;}</style></defs><line class="cls-1" x1="0" y1="5.86" x2="68.32" y2="5.86"/><line class="cls-1" x1="65.76" y1="5.86" x2="60.27" y2=".35"/><line class="cls-1" x1="60.27" y1="11.37" x2="65.76" y2="5.86"/></svg>'
                            )
                            no-repeat right center / auto 100%;
                    }
                }
            }
        }
    }
    &__btn {
        @include c-button-view-short();
        margin-right: 21px;
        margin-bottom: 21px;
        @include media-down(md) {
            margin-right: 17px;
            margin-bottom: 17px;
        }
    }
}
.block-works {
    position: relative;
    padding: 170px map-get($spacers, 3) 130px;
    background-color: #efefef;
    @include media-down(md) {
        padding-top: 110px;
        padding-bottom: 40px;
    }
    @include media-down(sm) {
        padding-inline: 0;
    }
    &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        // height: 335px;
        height: 41%;
        background-color: #fff;
        @include media-down(md) {
            height: 44%;
        }
    }
    &__inner {
        position: relative;
        z-index: 1;
        width: 100%;
        max-width: 1200px;
        margin-inline: auto;
        @include media-down(sm) {
            overflow: hidden;
        }
    }
    &__title {
        @include c-block-title(center);
        margin-bottom: 55px;
    }
    .swiper-area {
        position: relative;
        width: 100%;
        padding-bottom: 70px;
        @include media-down(md) {
            padding-bottom: 40px;
        }
    }
    .works_slide {
        @include media-down(sm) {
            overflow: visible;
            width: 300px;
        }
    }
    .btn-wrap {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 25px;
        width: 100%;
        .works-prev,
        .works-next {
            position: static;
            width: 35px;
            height: 6px;
            &::after {
                display: none;
            }
        }
        .works-prev {
            background: svgUrlFunc(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.32 6.36"><defs><style>.cls-1{fill:none;stroke:#454547;stroke-miterlimit:10;}</style></defs><line class="cls-1" x1="34.32" y1="5.86" x2="0" y2="5.86"/><line class="cls-1" x1="2.57" y1="5.86" x2="8.05" y2=".35"/></svg>'
                )
                no-repeat center / contain;
        }
        .works-next {
            background: svgUrlFunc(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.32 6.36"><defs><style>.cls-1{fill:none;stroke:#454547;stroke-miterlimit:10;}</style></defs><line class="cls-1" x1="0" y1="5.86" x2="34.32" y2="5.86"/><line class="cls-1" x1="31.75" y1="5.86" x2="26.26" y2=".35"/></svg>'
                )
                no-repeat center / contain;
        }
    }
    &__btnwrap {
        @include media-up(md) {
            position: absolute;
            top: 34px;
            right: 50px;
        }
        @include media-down(md) {
            display: flex;
            justify-content: flex-end;
            margin-top: 27px;
        }
        @include media-down(sm) {
            padding-inline: map-get($spacers, 3);
        }
    }
    &__btn {
        @include c-button-view();
    }
}
.block-works-item {
    a {
        position: relative;
        text-decoration: none !important;
    }
    &__img-wrap {
        overflow: hidden;
        aspect-ratio: 372 / 263;
        width: 100%;
        margin-bottom: 22px;
        border-radius: 5px;
        img {
            transition: $transition-base;
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        }
    }
    .cate {
        position: absolute;
        z-index: 1;
        top: 13px;
        left: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 86px;
        height: 24px;
        padding: 0 5px;
        font-size: calc(10 / 16 * $font-size-base);
        font-weight: bold;
        letter-spacing: 0.08em;
        background-color: #fff;
        border-radius: 3px;
    }
    &__body {
        padding-inline: 12px;
    }
    .title {
        margin-bottom: 17px;
        font-size: calc(18 / 16 * $font-size-base);
        font-feature-settings: 'palt';
        font-weight: bold;
        color: #454547;
        letter-spacing: 0.12em;
    }
    .tags {
        display: flex;
        gap: 10px;
        .tag {
            font-size: calc(10 / 16 * $font-size-base);
            color: #7a7072;
            letter-spacing: 0.08em;
        }
    }
    @media (hover: hover) {
        &:hover {
            img {
                transform: scale(1.1);
            }
            .title {
                text-decoration: underline !important;
            }
        }
    }
}
.block-image {
    width: 100%;
    height: 594px;
    background-attachment: fixed;
    background-image: url(/assets/img/webp/home/back-image@2x.webp);
    background-position: center;
    background-size: cover;
    @media (max-width: 1200px) {
        background-attachment: inherit;
    }
    @include media-down(lg) {
        height: 400px;
    }
    @include media-down(sm) {
        height: 200px;
        background-image: url(/assets/img/webp/home/back-image_sp@2x.webp);
    }
}
.block-news {
    padding-top: 125px;
    padding-inline: map-get($spacers, 3);
    @include media-down(md) {
        padding-top: 50px;
    }
    &__inner {
        position: relative;
        width: 100%;
        max-width: 1100px;
        margin-inline: auto;
        padding-bottom: 65px;
        border-bottom: solid 1px #ededed;
        @include media-up(lg) {
            display: flex;
        }
        @include media-down(md) {
            padding-bottom: 50px;
        }
    }
    &__head {
        @include media-up(lg) {
            width: 290px;
        }
        @include media-down(lg) {
            width: 100%;
        }
    }
    &__title {
        @include c-block-title();
        margin-bottom: 30px;
        @include media-down(lg) {
            align-items: center;
        }
    }
    &__content {
        @include media-up(lg) {
            width: calc(100% - 290px);
        }
        @include media-down(lg) {
            width: 100%;
        }
    }
    &__btnwrap {
        display: flex;
        justify-content: flex-end;
        margin-top: 65px;
        @include media-down(lg) {
            margin-top: 40px;
        }
    }
    &__btn {
        @include c-button-view();
    }
}
.block-news__categories {
    @include media-up(lg) {
        position: absolute;
        top: 100px;
        left: -290px;
        width: 190px;
    }
    @include media-down(lg) {
        width: 100%;
        margin-top: 42px;
    }
    @include media-down(md) {
        padding-inline: 5px;
    }
}
.block-news__categories-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 100%;
    @include media-down(lg) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 0 15px;
    }
    @include media-down(md) {
        grid-template-columns: repeat(2, 1fr);
    }
}
.block-news__categories-item {
    border-bottom: solid 1px #d9d9d9;
    &:not(:last-of-type) {
        @include media-up(md) {
        }
    }
    a {
        transition: $transition-base;
        display: block;
        padding: 14px 25px 14px 0;
        font-size: calc(12 / 16 * $font-size-base);
        text-decoration: none !important;
        color: #000000;
        letter-spacing: 0.16em;
        line-height: 1;
        background: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.31 7.93"><defs><style>.cls-1{fill:none;stroke:#454547;stroke-miterlimit:10;}</style></defs><line class="cls-1" x1="3.95" y1="3.97" x2=".35" y2=".35"/><line class="cls-1" x1=".35" y1="7.58" x2="3.95" y2="3.97"/></svg>'
            )
            no-repeat right 7px center / 4px auto;
        @include media-down(sm) {
        }
        @media (hover: hover) {
            &:hover {
                color: #bb9800;
                background-position: right 2px center;
            }
        }
    }
}
.block-news__list {
    display: grid;
    gap: 50px;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
    @include media-up(lg) {
        grid-template-columns: repeat(3, 1fr);
        max-width: 736px;
    }
    @include media-down(lg) {
        grid-template-columns: repeat(4, 1fr);
        gap: 15px;
    }
    @include media-down(md) {
        grid-template-columns: repeat(2, 1fr);
        gap: 25px 15px;
    }
}
.block-news__item {
    @include media-up(lg) {
        &:nth-child(n + 4) {
            display: none;
        }
    }
    &__link {
        text-decoration: none !important;
        @media (hover: hover) {
            &:hover {
                .title {
                    text-decoration: underline;
                }
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
    &-img {
        aspect-ratio: 1 / 1;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 20px;
        @include media-down(md) {
            margin-bottom: 15px;
        }
        img {
            transition: $transition-base;
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        }
    }
    .title {
        margin-bottom: 25px;
        font-size: calc(13 / 16 * $font-size-base);
        letter-spacing: 0.08em;
        line-height: calc(23 / 13);
        @include media-down(md) {
            margin-bottom: 15px;
        }
        &.is-new:after {
            display: inline-block;
            content: 'NEW';
            color: $red;
            font-family: 'Roboto', $font-family-sans-serif;
            font-feature-settings: 'palt';
            margin-left: 0.2em;
            font-size: calc(12 / 16 * $font-size-base);
            margin-left: 12px;
            color: $primary;
            letter-spacing: 0.14em;
            @include media-down(md) {
                nl: 10px;
            }
        }
    }
    .date-cate {
        display: flex;
        align-items: center;
        gap: 6px;
        flex-wrap: wrap;
        font-size: calc(11 / 16 * $font-size-base);
        color: #7a7072;
        letter-spacing: 0.08em;
        line-height: 1;
        .date {
            @include font-en();
            font-weight: 600;
            // margin-right: 6px;
            padding-right: 7px;
            border-right: solid 1px #7a7072;
        }
        .cate {
        }
    }
}
.block-instagram {
    padding: 105px map-get($spacers, 3) 120px;
    @include media-down(md) {
        padding: 45px map-get($spacers, 3) 55px;
    }
    &__inner {
        width: 100%;
        max-width: 1100px;
        margin-inline: auto;
    }
    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
        @include media-down(md) {
            margin-bottom: 23px;
        }
        @include media-down(sm) {
            flex-direction: column;
        }
        ._text-link {
            transition: $transition-base;
            @include font-en();
            font-size: calc(12 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            font-weight: 600;
            color: #373737;
            text-decoration: none !important;
            letter-spacing: 0.16em;
            @media (hover: hover) {
                &:hover {
                    color: #bb9800;
                }
            }
        }
    }
    &__title {
        display: flex;
        align-items: center;
        gap: 21px;
        margin: 0;
        @include font-en();
        font-size: calc(24 / 16 * $font-size-base);
        font-feature-settings: 'palt';
        font-weight: 500;
        letter-spacing: 0.16em;
        @include media-down(sm) {
            margin-bottom: 20px;
        }
    }
    .instafeed {
        overflow: hidden;
        margin-bottom: 65px;
        @include media-down(md) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 10px;
            margin-bottom: 33px;
        }
        &__item {
            overflow: hidden;
            aspect-ratio: 1;
            @include media-up(md) {
                float: left;
            }
            &:first-of-type {
                @include media-up(md) {
                    width: calc((100% - 22px) / 5 * 2);
                }
                @include media-down(md) {
                    width: 100%;
                }
            }
            &:not(:first-of-type) {
                @include media-up(md) {
                    width: calc((100% - 56px) / 5);
                    margin-left: 14px;
                }
                @include media-down(md) {
                    width: calc((100% - 10px) / 2);
                }
            }
            &:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4) {
                @include media-up(md) {
                    margin-bottom: 14px;
                }
            }
            &:nth-of-type(7) {
                @include media-down(md) {
                    //   display: none;
                }
            }
            a {
                transition: $transition-base;
                @media (hover: hover) {
                    &:hover {
                        // opacity: 0.8;
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
            img {
                transition: $transition-base;
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
            }
        }
    }
    &__btnwrap {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
        @include media-down(sm) {
            grid-template-columns: 1fr;
            gap: 7px;
        }
    }
    &__btn {
        transition: $transition-base;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        height: 97px;
        padding-right: 15px;
        @include font-en();
        font-size: calc(22 / 16 * $font-size-base);
        font-feature-settings: 'palt';
        font-weight: 500;
        text-decoration: none !important;
        letter-spacing: 0.16em;
        border: solid 1px #454547;
        border-radius: 3px;
        background: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.51 11.72"><defs><style>.cls-1{fill:none;stroke:#454547;stroke-miterlimit:10;}</style></defs><line class="cls-1" x1="0" y1="5.86" x2="20.51" y2="5.86"/><line class="cls-1" x1="17.94" y1="5.86" x2="12.45" y2=".35"/><line class="cls-1" x1="12.45" y1="11.37" x2="17.94" y2="5.86"/></svg>'
            )
            no-repeat right 18px center / 21px auto;
        @media (hover: hover) {
            &:hover {
                background-position: right 13px center;
            }
        }
        @include media-down(md) {
            gap: 11px;
            height: 70px;
            font-size: calc(20 / 16 * $font-size-base);
        }
        &._youtube {
            @include media-down(md) {
                img {
                    width: 23px;
                    height: auto;
                }
            }
        }
        &._line {
            @include media-down(md) {
                img {
                    width: 28px;
                    height: auto;
                }
            }
        }
    }
}
