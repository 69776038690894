@mixin table-typeA {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    tr:first-of-type {
        th,
        td {
            border-top: none;
            @include media-down(md) {
                &::before {
                    display: none;
                }
            }
        }
    }
    th,
    td {
        font-size: calc(14 / 16 * $font-size-base);
        font-feature-settings: 'palt';
        vertical-align: top;
        color: #373737;
        letter-spacing: 0.08em;
        line-height: calc(26 / 14);
        @include media-down(md) {
            display: block;
            line-height: calc(24 / 14);
        }
    }
    th {
        padding: 18px 0 18px 25px;
        font-weight: bold;
        white-space: nowrap;
        border-top: solid 1px #bcbcbd;
        @include media-up(md) {
            width: 161px;
        }
        @include media-down(md) {
            position: relative;
            width: 100%;
            padding: 12px 0 0 0;
            border-top: solid 1px #e3e3e3;
            // border-width: 0;
            // padding-bottom: 0;
            &::before {
                content: '';
                position: absolute;
                top: -1px;
                left: 0;
                width: 73px;
                height: 1px;
                background-color: #bcbcbd;
            }
        }
    }
    td {
        padding: 18px 0 18px 45px;
        border-top: solid 1px #e3e3e3;
        @include media-down(md) {
            width: 100%;
            padding: 1px 0 12px 0;
            border-top: none;
        }
    }
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
            position: relative;
            &::before {
                content: '';
                position: relative;
                top: -1px;
                display: inline-block;
                width: 8px;
                height: 8px;
                margin-right: 7px;
                background-color: #c3c3c3;
                border-radius: 50%;
            }
        }
    }
}
@mixin table-typeB {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    tr:first-of-type {
        th,
        td {
            border-top: none;
        }
    }
    th,
    td {
        padding: 10px 8px;
        font-size: calc(12 / 16 * $font-size-base);
        font-weight: normal;
        color: #838081;
        letter-spacing: 0.08em;
        border-top: solid 1px #dad9d9;
    }
    th {
        width: 100px;
        padding: 10px 8px;
    }
    td {
        padding: 10px 0;
    }
}
