@mixin c-module-title() {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.75em;
    font-weight: normal;
    letter-spacing: 0.22em;
    text-indent: 0.22em;
    font-size: $h2-font-size;
    @include media-down(lg) {
        font-size: 1.875rem;
    }
    @include media-down(md) {
        font-size: $h4-font-size;
    }

    span {
        font-family: 'Roboto', $font-family-sans-serif;
        font-weight: $font-weight-normal;
    }

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        display: block;
        width: 82px;
        height: 1px;
        margin: auto;
        background: $primary;
    }
}

// font-weightは別途指定
// ExtraLight 200
// Regular 400
// Medium 500
// SemiBold 600
// Bold 700
@mixin font-en() {
    font-family: 'Mulish', sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

@mixin c-block-title($align: flex-start) {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    color: #373737;
    @if $align == 'center' {
        align-items: center;
    }
    @include media-down(md) {
    }
    ._en {
        @include font-en();
        font-size: calc(35 / 16 * $font-size-base);
        font-weight: 700;
        letter-spacing: 0.16em;
        line-height: 1;
        @include media-down(md) {
        }
    }
    ._ja {
        margin-top: 15px;
        font-size: calc(12 / 16 * $font-size-base);
        font-weight: bold;
        letter-spacing: 0.16em;
        @include media-down(md) {
        }
    }
}
@mixin c-title-local($align: flex-start) {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 117px;
    @include media-down(md) {
        padding-top: 67px;
    }
    @if $align == 'center' {
        align-items: center;
    }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 1px;
        height: 105px;
        margin: auto;
        background-color: #9c9c9d;
        @include media-down(md) {
            height: 55px;
        }
    }
    ._ja {
        font-size: calc(28 / 16 * $font-size-base);
        font-weight: bold;
        text-align: center;
        color: #454547;
        letter-spacing: 0.16em;
        @include media-down(md) {
            font-size: calc(21 / 16 * $font-size-base);
            letter-spacing: 0.14em;
        }
    }
    ._en {
        @include font-en();
        margin-top: 12px;
        font-size: calc(14 / 16 * $font-size-base);
        font-weight: 700;
        letter-spacing: 0.16em;
        color: #9c9c9d;
        @include media-down(md) {
            margin-top: 8px;
            font-size: calc(13 / 16 * $font-size-base);
        }
    }
}