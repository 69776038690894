.rwd002-moduletitle {
    margin-top: map-get($spacers, 6);
    margin-bottom: map-get($spacers, 4);
    text-align: center;
    @include media-down(md) {
        margin-top: map-get($spacers, 4);
        margin-bottom: map-get($spacers, 4);
    }
    &__inner {
        @include c-module-title;
        padding-left: 1.6875rem;
        padding-right: 1.6875rem;
    }
}

.moduletitle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 220px;
    @include media-down(md) {
        height: 140px;
    }
    .is--ouchi &,
    .is--renovetion & {
        height: 350px;
        @include media-down(md) {
            height: 215px;
        }
        img {
          height: auto;
        }
    }
    .is--ouchi & {
      img {
        @include media-down(md) {
          width: 192px;
        }
      }
    }
    .is--renovetion & {
      img {
        @include media-down(md) {
          width: 288px;
        }
      }
    }
    ._title {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin: 0;
        line-height: 1;
    }
    ._ja {
        margin-top: 16px;
        font-size: calc(12 / 16 * $font-size-base);
        font-weight: bold;
        letter-spacing: 0.16em;
    }
    ._en {
        @include font-en();
        font-size: calc(35 / 16 * $font-size-base);
        font-weight: 700;
        letter-spacing: 0.16em;
    }
}
