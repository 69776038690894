@function svg-right-angle($theme: $primary) {
    @return svgUrlFunc(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$theme}" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>'
    );
}

@function svg-bottom-angle($theme: $body-color) {
    @return svgUrlFunc(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$theme}" d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"/></svg>'
    );
}

.btn-icon-right-angle {
    position: relative;
    &:after {
        position: absolute;
        content: '';
        top: calc(50% - 0.3em);
        right: 1em;
        width: 1em;
        height: 0.6em;
        background: center / contain no-repeat;
        background-image: svg-right-angle();
    }
    &:hover:after,
    &:focus:after {
        background-image: svg-right-angle($white);
    }
}

.btn-icon-right-angle-white {
    @extend .btn-icon-right-angle;
    &:after {
        background-image: svg-right-angle($white);
    }
}

.btn-icon-bottom-angle {
    position: relative;
    &:after {
        position: absolute;
        content: '';
        top: calc(50% - 0.3em);
        right: 0;
        width: 2em;
        height: 0.6em;
        transition: all 0.5s ease-in-out;
        background: center / contain no-repeat;
        background-image: svg-bottom-angle();
        transform: rotateX(180deg);
    }
    &.collapsed:after {
        transform: rotateX(0deg);
    }
}

@mixin c-button-view($align: flex-start) {
    transition: $transition-base;
    position: relative;
    padding-right: 80px;
    @include font-en();
    font-size: calc(12 / 16 * $font-size-base);
    font-weight: 600;
    text-decoration: none !important;
    letter-spacing: 0.18em;
    @include media-down(md) {
        padding-right: 50px;
    }
    &::before {
        transition: $transition-base;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 68px;
        height: 11px;
        margin: auto;
        background: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.32 11.72"><defs><style>.cls-1{fill:none;stroke:#454547;stroke-miterlimit:10;}</style></defs><line class="cls-1" x1="0" y1="5.86" x2="68.32" y2="5.86"/><line class="cls-1" x1="65.76" y1="5.86" x2="60.27" y2=".35"/><line class="cls-1" x1="60.27" y1="11.37" x2="65.76" y2="5.86"/></svg>'
            )
            no-repeat right center / 100% auto;
        @include media-down(md) {
            width: 40px;
            background-size: auto 100% !important;
        }
    }
    @media (hover: hover) {
        &:hover {
            color: #bb9800;
            &::before {
                transform: translateX(5px);
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.32 11.72"><defs><style>.cls-1{fill:none;stroke:#beb42f;stroke-miterlimit:10;}</style></defs><line class="cls-1" x1="0" y1="5.86" x2="68.32" y2="5.86"/><line class="cls-1" x1="65.76" y1="5.86" x2="60.27" y2=".35"/><line class="cls-1" x1="60.27" y1="11.37" x2="65.76" y2="5.86"/></svg>'
                    )
                    no-repeat right center / 100% auto;
            }
        }
    }
}
@mixin c-button-view-short($align: flex-start) {
    transition: $transition-base;
    position: relative;
    padding-right: 38px;
    @include font-en();
    font-size: calc(12 / 16 * $font-size-base);
    font-weight: 600;
    text-decoration: none !important;
    letter-spacing: 0.16em;
    @include media-down(md) {
        padding-right: 30px;
    }
    &::before {
        transition: $transition-base;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 20px;
        height: 11px;
        margin: auto;
        background: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.32 11.72"><defs><style>.cls-1{fill:none;stroke:#454547;stroke-miterlimit:10;}</style></defs><line class="cls-1" x1="0" y1="5.86" x2="68.32" y2="5.86"/><line class="cls-1" x1="65.76" y1="5.86" x2="60.27" y2=".35"/><line class="cls-1" x1="60.27" y1="11.37" x2="65.76" y2="5.86"/></svg>'
            )
            no-repeat right center / auto 100%;
        @include media-down(md) {
            background-size: auto 100% !important;
        }
    }
    @media (hover: hover) {
        &:hover {
            color: #bb9800;
            &::before {
                transform: translateX(5px);
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.32 11.72"><defs><style>.cls-1{fill:none;stroke:#beb42f;stroke-miterlimit:10;}</style></defs><line class="cls-1" x1="0" y1="5.86" x2="68.32" y2="5.86"/><line class="cls-1" x1="65.76" y1="5.86" x2="60.27" y2=".35"/><line class="cls-1" x1="60.27" y1="11.37" x2="65.76" y2="5.86"/></svg>'
                    )
                    no-repeat right center / auto 100%;
            }
        }
    }
}
@mixin c-button-view-border($align: flex-start) {
    transition: $transition-base;
    position: relative;
    width: 94px;
    padding: 7px 0;
    @include font-en();
    font-size: calc(12 / 16 * $font-size-base);
    font-weight: 600;
    text-decoration: none !important;
    letter-spacing: 0.18em;
    border-bottom: solid 1px #454547;
    @include media-down(md) {
    }
    &::before {
        transition: $transition-base;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 1px;
        width: 15px;
        height: 7px;
        margin: auto;
        background: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.61 7.4"><defs><style>.cls-1{fill:none;stroke:#454547;stroke-miterlimit:10;}</style></defs><line class="cls-1" x1="0" y1="3.7" x2="14.61" y2="3.7"/><line class="cls-1" x1="12.05" y1="3.7" x2="8.71" y2=".35"/><line class="cls-1" x1="8.71" y1="7.05" x2="12.05" y2="3.7"/></svg>'
            )
            no-repeat right center / 100% auto;
        @include media-down(md) {
            width: 40px;
            background-size: auto 100% !important;
        }
    }
    @media (hover: hover) {
        &:hover {
            color: #bb9800;
            border-bottom-color: #beb42f;
            &::before {
                transform: translateX(5px);
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.61 7.4"><defs><style>.cls-1{fill:none;stroke:#beb42f;stroke-miterlimit:10;}</style></defs><line class="cls-1" x1="0" y1="3.7" x2="14.61" y2="3.7"/><line class="cls-1" x1="12.05" y1="3.7" x2="8.71" y2=".35"/><line class="cls-1" x1="8.71" y1="7.05" x2="12.05" y2="3.7"/></svg>'
                    )
                    no-repeat right center / 100% auto;
            }
        }
    }
}
@mixin c-button-open($align: flex-start) {
    transition: $transition-base;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 98px;
    @include font-en();
    font-size: calc(16 / 16 * $font-size-base);
    font-weight: 700;
    text-decoration: none !important;
    letter-spacing: 0.16em;
    border: solid 1px #373737;
    border-radius: 3px;
    @include media-down(md) {
        height: 70px;
    }
    ._icon {
        margin-right: 9px;
    }
    ._icon-open {
        position: absolute;
        bottom: 14px;
        right: 24px;
        margin-left: 9px;
        font-size: calc(11 / 16 * $font-size-base);
        letter-spacing: 0.24em;
        line-height: 1;
        @include media-down(md) {
            bottom: 11px;
            right: 8px;
        }
    }
    @media (hover: hover) {
        &:hover {
            opacity: 0.8;
        }
    }
}
