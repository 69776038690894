.is--works.is--list {
    .rwd002-taglist {
        // display: none;
    }
    .rwd002-container {
        margin-top: 70px;
        @include media-down(md) {
            margin-top: 35px;
        }
    }
}
.rwd002-taglist {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-inline: map-get($spacers, 3);
    @include media-down(lg) {
        margin-top: 30px;
    }
    @include media-down(md) {
        margin-top: 40px;
    }
    &__cats {
        position: relative;
        @include media-up(md) {
            max-width: fit-content;
            display: flex;
            flex-wrap: wrap;
            gap: 13px;
            margin-bottom: 40px;
        }
        @include media-down(md) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 5px;
            width: 100%;
            margin-bottom: 25px;
        }
        .text-category {
            position: absolute;
            top: 6px;
            left: -130px;
            @include font-en();
            margin: 0;
            padding-left: 25px;
            font-size: calc(14 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            font-weight: 500;
            color: #373737;
            letter-spacing: 0.16em;
            background: url(/assets/img/icon/icon-round.svg) no-repeat left
                center / 16px auto;
            @include media-down(lg) {
                top: -35px;
                left: 0;
            }
        }
    }
    &__catBtn {
        transition: $transition-base;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: calc(14 / 16 * $font-size-base);
        font-feature-settings: 'palt';
        font-weight: bold;
        text-decoration: none !important;
        color: #7a7072;
        letter-spacing: 0.08em;
        border: solid 1px #c8c8c8;
        border-radius: 3px;
        @include media-up(md) {
            width: 182px;
            height: 36px;
        }
        @include media-down(md) {
            width: 100%;
            height: 32px;
            font-size: calc(13 / 16 * $font-size-base);
        }
        &.current {
            color: #fff;
            background-color: #7a7072;
            border-color: #7a7072;
        }
        @media (hover: hover) {
            &:hover {
                color: #fff;
                background-color: #7a7072;
                border-color: #7a7072;
            }
        }
    }
    &__tags {
    }
    &__list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 16px;
        @include media-down(md) {
            gap: 14px;
        }
    }
    &__tagBtn {
        font-size: calc(13 / 16 * $font-size-base);
        color: #373737;
        letter-spacing: 0.08em;
    }
}

.works-list {
    padding-inline: map-get($spacers, 3);
    &__inner {
        width: 100%;
        max-width: 1200px;
        margin-inline: auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 70px 40px;
        @include media-down(lg) {
            gap: 40px 30px;
        }
        @include media-down(md) {
            grid-template-columns: repeat(2, 1fr);
            gap: 30px;
        }
        @include media-down(sm) {
            grid-template-columns: 1fr;
        }
    }
    &__title {
        margin-bottom: 50px;
        font-size: calc(18 / 16 * $font-size-base);
        font-weight: bold;
        text-align: center;
        color: #312a24;
        letter-spacing: 0.1em;
    }
}
.works-item {
    a {
        position: relative;
        text-decoration: none !important;
    }
    &__img-wrap {
        position: relative;
        overflow: hidden;
        aspect-ratio: 372 / 263;
        width: 100%;
        margin-bottom: 22px;
        border-radius: 5px;
        @include media-down(sm) {
            margin-bottom: 25px;
        }
        img {
            transition: $transition-base;
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        }
    }
    .cate {
        position: absolute;
        z-index: 1;
        top: 13px;
        left: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 86px;
        height: 24px;
        padding: 0 5px;
        font-size: calc(10 / 16 * $font-size-base);
        font-weight: bold;
        letter-spacing: 0.08em;
        background-color: #fff;
        border-radius: 3px;
    }
    &__body {
        @include media-up(sm) {
            padding-inline: 12px;
        }
    }
    .title {
        margin-bottom: 17px;
        font-size: calc(18 / 16 * $font-size-base);
        font-feature-settings: 'palt';
        font-weight: bold;
        color: #454547;
        letter-spacing: 0.12em;
    }
    .tags {
        display: flex;
        gap: 10px;
        .tag {
            font-size: calc(10 / 16 * $font-size-base);
            color: #7a7072;
            letter-spacing: 0.08em;
        }
    }
    @media (hover: hover) {
        &:hover {
            img {
                transform: scale(1.1);
            }
            .title {
                text-decoration: underline !important;
            }
        }
    }
}
.works-list.is-related {
    .works-list__inner {
        margin-bottom: 100px;
    }
    .works-item-wrap:nth-child(n + 4) {
        display: none;
    }
}
.is--works.is--detail {
    .rwd002-container {
        margin-top: -10px;
        @include media-down(md) {
            margin-top: 0;
        }
    }
    .rwd002-detail {
        margin-top: 0;
        margin-bottom: 150px;
        @include media-down(md) {
            margin-bottom: 80px;
        }
        &__head {
        }
        &__category {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 86px;
            min-width: 86px;
            // min-width: max(9em, 86px);
            height: 24px;
            margin: 0 auto 28px;
            padding-inline: 5px;
            font-size: calc(10 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            font-weight: bold;
            color: #fff;
            letter-spacing: 0.08em;
            background-color: #7a7072;
            border-radius: 3px;
            @include media-down(md) {
                margin-bottom: 20px;
            }
        }
        &__title {
            margin-bottom: 22px;
            padding-bottom: 0;
            font-size: calc(30 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            font-weight: bold;
            text-align: center;
            color: #373737;
            letter-spacing: 0.16em;
            line-height: 30 / 25;
            border-bottom: none;
            @include media-down(md) {
                margin-bottom: 18px;
                font-size: calc(25 / 16 * $font-size-base);
            }
        }
        &__desc {
            margin-top: 0;
            margin-bottom: 33px;
            font-size: calc(13 / 16 * $font-size-base);
            font-feature-settings: 'palt';
            text-align: center;
            color: #373737;
            letter-spacing: 0.16em;
            @include media-down(md) {
                margin-bottom: 20px;
            }
        }
        &__image {
            margin-bottom: 20px;
        }
        &__entry {
            ._lead {
                margin: 0;
                font-size: calc(17 / 16 * $font-size-base);
                font-feature-settings: 'palt';
                font-weight: bold;
                text-align: center;
                color: #373737;
                letter-spacing: 0.12em;
                line-height: calc(31 / 17);
                @include media-down(md) {
                    font-size: calc(16 / 16 * $font-size-base);
                    line-height: calc(27 / 16);
                }
            }
            ._post {
                @include media-up(lg) {
                    padding: 50px 80px;
                }
                @include media-down(lg) {
                    padding: 50px 30px;
                }
                @include media-down(md) {
                    padding: 30px 0;
                }
                ._title {
                    position: relative;
                    margin-bottom: 33px;
                    padding-bottom: 20px;
                    font-size: calc(21 / 16 * $font-size-base);
                    font-feature-settings: 'palt';
                    font-weight: bold;
                    color: #7a7072;
                    letter-spacing: 0.08em;
                    line-height: 1.6;
                    @include media-down(md) {
                        margin-bottom: 22px;
                        font-size: calc(18 / 16 * $font-size-base);
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 50px;
                        height: 1px;
                        background-color: #7a7072;
                    }
                }
                ._text {
                    margin-bottom: 45px;
                    font-size: calc(15 / 16 * $font-size-base);
                    font-feature-settings: 'palt';
                    letter-spacing: 0.08em;
                    line-height: calc(31 / 15);
                    @include media-down(md) {
                        margin-bottom: 40px;
                        letter-spacing: 0.06em;
                        line-height: calc(28 / 15);
                    }
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
            ._owner-detail {
                display: flex;
                align-items: center;
                gap: 70px;
                margin-bottom: 75px;
                @include media-down(md) {
                    flex-direction: column;
                    gap: 35px;
                    margin-bottom: 40px;
                }
                img {
                    flex-shrink: 0;
                    width: 351px;
                    max-width: 100%;
                    height: auto;
                }
                &__content {
                    width: 100%;
                    p {
                        margin-bottom: 5px;
                        font-family: 'Roboto', $font-family-sans-serif;
                        font-size: calc(16 / 16 * $font-size-base);
                        font-feature-settings: 'palt';
                        color: #7a7072;
                        letter-spacing: 0.08em;
                        line-height: calc(31 / 16);
                    }
                    table {
                        @include table-typeB();
                    }
                }
            }
            img {
                display: block;
                width: 100%;
                max-width: 100%;
                height: auto;
                margin: auto;
            }
            ._img-col2 {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 40px;
                @include media-down(md) {
                    grid-template-columns: 1fr;
                    gap: 30px;
                }
            }
            ._img-map {
                display: flex;
                justify-content: center;
                img {
                    max-width: 850px;
                }
            }
        }
    }
    .rwd002-taglist {
        @include media-down(md) {
            margin-bottom: 50px;
        }
    }
}
